import React, { memo } from 'react';

import CarrouselTimeLineTimeItem from './CarrouselTimeLineTimeItem';

import './carrousel-timeline-time.scss';

// carrousel timeline time
const CarrouselTimelineTime = ({ current, items }) => {
  // render
  return (
    <div className="carrousel-timeline-time">
      <ul className="carrousel-timeline-time--list">
        {items && items.map((item, index) => <li className="carrousel-timeline-time--item" key={index}>
          <CarrouselTimeLineTimeItem {...item} current={index === current} active={index <= current} />
        </li>)}
      </ul>
    </div>
  );
};

export default memo(CarrouselTimelineTime);