import React from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../helpers/useLinkRefactory';

import LinkElement from '../LinkElement';
import Picture from '../Picture';

import './image-text.scss';

// image text
const ImageText = ({ value }) => {
  // link
  const { linkElement } = useLinkRefactory();

  // props
  const { button_label, image_align, image, body, title } = value;
  const link = linkElement(value);

  // render
  return (
    <div className="image-text" data-type={image_align}>
      <div className="wrapper">
        {title &&
          <h1 className="image-text--title">{title}</h1>}

        {body &&
          <div className="image-text--body" dangerouslySetInnerHTML={{ __html: body }} />}

        {image && <div className="image-text--image">
          <Picture className="image" image={image} />
        </div>}

        {link &&
          <LinkElement className="btn btn-default" link={link}>{button_label}</LinkElement>}
      </div>
    </div>
  );
};

ImageText.propTypes = {
  value: PropTypes.object.isRequired,
};

export default ImageText;