import React, { useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NavContext } from '../../../providers/NavProvider';
import useNavigation from '../../../helpers/useNavigation';

import TabSubMenuList from '../TabSubMenuList';

import './tab-submenu-content.scss';

// tab sub menu content
const TabSubMenuContent = () => {
  // use nav
  const { routeParentSubmenu } = useNavigation();

  // nav context
  const navContext = useContext(NavContext);
  const { route, routes } = navContext;

  // state
  const [ submenu, setSubmenu ] = useState([]);

  // find sub menu
  const findSubmenu = useCallback((route) => {
    if (route instanceof Object === false) return false;

    const routeSubmenu = routeParentSubmenu(route, routes);

    if (routeSubmenu && routeSubmenu.length > 0) setSubmenu(routeSubmenu[0].submenu);
  }, [ routes, routeParentSubmenu ]);

  // use effect
  useEffect(() => {
    findSubmenu(route);
  }, [ route, findSubmenu ]);

  // render
  return (
    <div className="wrapper">
      <div className="tab-submenu--content">
        <TabSubMenuList items={submenu} />
      </div>
    </div>
  );
};

TabSubMenuContent.propTypes = {
  any: PropTypes.any,
};

export default TabSubMenuContent;