import React from 'react';
import PropTypes from 'prop-types';

import './product-credits-juridic-item.scss';

// product credits juridic item
const ProductCreditsJuridicItem = ({ descricao, nome, subtitulo }) => {
  // render
  return (
    <div className="product-credits-juridic-item">
      <p className="title">
        <span className="subtitle">{subtitulo}</span>
        <span className="name">{nome}</span>
      </p>

      <div className="description" dangerouslySetInnerHTML={{ __html: descricao }} />
    </div>
  );
};

ProductCreditsJuridicItem.propTypes = {
  descricao: PropTypes.string,
  nome: PropTypes.string,
  subtitulo: PropTypes.string,
}

export default ProductCreditsJuridicItem;