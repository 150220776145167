import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import clamp from 'lodash-es/clamp';
import { useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import SliderBase from '../../Slider/Base';
import WebdoorInfo from '../WebdoorInfo';
import WebdoorItem from '../WebdoorItem';

import './webdoor-content.scss';

// Webdoor content
const WebdoorContent = ({ current, items, last, onPrevNext, setCurrent }) => {
  // props set
  const [ props, set ] = useSprings(items.length, i => ({
    x: i * window.innerWidth,
    scale: 1,
    display: 'block'
  }));

  // drag
  const drag = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (down && distance > window.innerWidth / 2)
      cancel((setCurrent(clamp(current + (xDir > 0 ? -1 : 1), 0, items.length - 1))));
    
    set(i => {
      if (i < current - 1 || i > current + 1) return { display: 'none' };

      const x = (i - current) * window.innerWidth + (down ? mx : 0);
      const scale = down ? 1 - distance / window.innerWidth / 2 : 1;
      return { x, scale, display: 'block' };
    });
  });

  // use effect
  useEffect(() => {
    const init = () => {
      set(i => {
        if (i < current - 1 || i > current + 1) {
          return { display: 'none' };
        }
  
        const x = (i - current) * window.innerWidth;
        const scale = 1;
  
        return { x, scale, display: 'block' };
      });
    };

    init();
  }, [ current, set ]);

  // render
  return (
    <Fragment>
      <WebdoorInfo current={current} last={last} onPrevNext={onPrevNext} items={items} />

      <div className="webdoor--content">
        {props.map(({ x, display, scale }, i) => (
          <WebdoorItem drag={drag} display={display} items={items} scale={scale} x={x} i={i} key={i} />))}
      </div>
    </Fragment>
  )
};

WebdoorContent.propTypes = {
  current: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  last: PropTypes.number,
  onPrevNext: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default SliderBase(WebdoorContent);
