import React from 'react';
import PropTypes from 'prop-types';

import { Parallax } from 'react-scroll-parallax';

import Picture from '../../Picture';

import './photo-special-image.scss';

// photo special image
const PhotoSpecialImage = ({ image, subtitle }) => {
  // render
  return (
    <div className="photo-special--image">
      <Parallax y={[-20, 0]}>
        <Picture className="image" image={image} />
        <p className="credits">{subtitle}</p>
      </Parallax>
    </div>
  );
};

PhotoSpecialImage.propTypes = {
  image: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
}

export default PhotoSpecialImage;