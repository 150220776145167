import React from 'react';
import PropTypes from 'prop-types';

import './modal.scss';

// modal
const Modal = ({ closeModal, children }) => {
  // render
  return (
    <div className="modal">
      <div className="modal--body--btn" onClick={closeModal}></div>
      <div className="modal--body">{children}</div>
    </div>
  );
};

Modal.propTypes = {
  closeModal: PropTypes.func,
  children: PropTypes.object
};

export default Modal;