import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import useWindowSize from '../../../../helpers/useWindowSize';

import ListHighlightsControls from '../ListHighlightsControls';
import ListHighlightsSlider from '../ListHighlightsSlider';
import SliderBase from '../../../Slider/Base';

import './list-highlights-list.scss';

// list hight lists list
const ListHighlightsList = ({ current, element, items, setCurrent, onPrevNext, type }) => {
  // size
  const size = useWindowSize();

  // get properties
  const getProperties = useCallback(() => {
    if (size.width <= 640) {
      return {
        limit: 1,
      };
    } else if (size.width < 1024) {
      return {
        limit: 3,
      };
    }

    return {
      limit: 4
    };
  }, [ size ]);

  // render
  return (
    <div className="list-highlights--list">
      <ListHighlightsControls
        current={current}
        getProperties={getProperties}
        onPrevNext={onPrevNext}
        total={items.length} />

      <ListHighlightsSlider
        current={current}
        element={element}
        getProperties={getProperties}
        items={items}
        setCurrent={setCurrent}
        type={type} />
    </div>
  );
};

ListHighlightsList.propTypes = {
  current: PropTypes.number.isRequired,
  element: PropTypes.object,
  items: PropTypes.array.isRequired,
  setCurrent: PropTypes.func.isRequired,
  onPrevNext: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default SliderBase(ListHighlightsList);