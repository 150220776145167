import React from 'react';
import PropTypes from 'prop-types';

import { animated as a } from 'react-spring';

import Picture from '../../Picture';

import './carrousel-timeline-item.scss';

// carrousel timeline item
const CarrouselTimeLineItem = ({ active, drag, display, x, item: { image, title, year } }) => {
  // render
  return (
    <a.div
      {...drag()}
      className="carrousel-timeline--item"
      data-active={active}
      style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }}>
      <div className="carrousel-timeline--item--info">
        <p className="year">{year}</p>
        <p className="title">{title}</p>
      </div>

      <Picture className="background" image={image} />
    </a.div>
  );
};

CarrouselTimeLineItem.propTypes = {
  active: PropTypes.bool.isRequired,
  drag: PropTypes.func.isRequired,
  display: PropTypes.object,
  item: PropTypes.object.isRequired,
  x: PropTypes.object,
}

export default CarrouselTimeLineItem;