import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './product-investment-item.scss';

// product investment item
const ProductInvestmentItem = ({ descricao, caracteristicas, nome, subtitulo, url }) => {
  // render
  return (
    <div className="product-investment-item">
      <p className="title">
        <span className="subtitle">{subtitulo}</span>
        <span className="name">{nome}</span>
      </p>

      <div className="description" dangerouslySetInnerHTML={{ __html: descricao }} />
      <div className="characteristics" dangerouslySetInnerHTML={{ __html: caracteristicas }} />

      {url && <a className="btn btn-border" href={url} target="_blank" rel="noopener noreferrer">
        <i className="icon icon-chat"></i>
        <span className="text">Download</span>
      </a>}
    </div>
  );
};

ProductInvestmentItem.propTypes = {
  descricao: PropTypes.string,
  caracteristicas: PropTypes.string,
  nome: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  url: PropTypes.string,
}

export default memo(ProductInvestmentItem);
