import React from 'react';
import PropTypes from 'prop-types';

import { Parallax } from 'react-scroll-parallax';

import ListProductsItem from './ListProductsItem';

import './list-products.scss';

// List Products
const ListProducts = ({ value }) => {
  // render
  return (
    <div className="list-products">
      <div className="wrapper">
        <Parallax y={[20, 0]}>
          <ul className="list-products--list">
            {value && value.map((item, index) =>
              <ListProductsItem item={item} key={index} />)}
          </ul>
        </Parallax>
      </div>
    </div>
  );
};

ListProducts.propTypes = {
  value: PropTypes.array.isRequired,
};

export default ListProducts;