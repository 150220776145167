import React from 'react';
import PropTypes from 'prop-types';

import Picture from '../../../Picture';

import './product-credits-fisic-item-title.scss';

// product credits fisic item title
const ProductCreditsFisicItemTitle = ({ name, icon }) => {
  // name
  const getName = name => {
    const text = name.split(' ');
    const words = [];

    for (let key in text) {
      words.push(<span key={key}>{text[key]}</span>);
    }

    return words;
  };

  // render
  return (
    <div className="product-credits-fisic-item--title">
      <Picture className="icone" image={icon} type={2} />

      <p className="name">{getName(name)}</p>
    </div>
  );
};

ProductCreditsFisicItemTitle.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
}

export default ProductCreditsFisicItemTitle;