import React from 'react';
import PropTypes from 'prop-types';

import SliderBase from '../../../../Slider/Base';
import SliderGallery from '../../../../Slider/Gallery';

import './categories-tab-content-item-slider.scss';

// categories tab content item slider
const CategoriesTabContentItemSlider = ({ current, items, onPrevNext }) => {
  // render
  return (
    <SliderGallery
      className="categories-tab-content-item-slider"
      current={current}
      onPrevNext={onPrevNext}
      width={285}>
      {items && items.map(({ element }, key) =>
        <div className="categories-tab-content-item-slider--element" key={key}>{element}</div>)}
    </SliderGallery>
  );
};

CategoriesTabContentItemSlider.propTypes = {
  current: PropTypes.number,
  items: PropTypes.array,
  onPrevNext: PropTypes.func.isRequired,
};

export default SliderBase(CategoriesTabContentItemSlider);