import React from 'react';
import PropTypes from 'prop-types';

import CategoriesTabContentList from './CategoriesTabContentList';

import './categories-tab-categories.scss';

// categories tab categories
const CategoriesTabCategories = ({ current, children, categories, items, setCurrent }) => {
  // render
  return (
    <div className="categories-tab-categories">
      <CategoriesTabContentList
        current={current}
        children={children}
        items={items}
        categories={categories}
        setCurrent={setCurrent} />
    </div>
  );
};

CategoriesTabCategories.propTypes = {
  categories: PropTypes.array,
  children: PropTypes.array,
  current: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  setCurrent: PropTypes.func.isRequired,
}

export default CategoriesTabCategories;