import React from 'react';
import PropTypes from 'prop-types';

import './footer-address.scss';

// footer address
const FooterAddress = ({ items }) => {
  // render
  return (
    <div className="footer--address">
      {items && items.map(({ endereco, telefone, local }, index) =>
        <div className="footer--address--item" key={index}>
          <p className="title">{local}</p>

          <ul className="list">
            <li className="list--item">
              <span className="icon icon-piker"></span>
              <span className="text">{endereco}</span>
            </li>
            <li className="list--item">
              <span className={`icon icon-phone`}></span>
              <a className="text" href={`tel:+${telefone}`}>{telefone}</a>
            </li>
          </ul>
        </div>)}
    </div>
  );
};

FooterAddress.propTypes = {
  items: PropTypes.array.isRequired,
}

export default FooterAddress;