import { useCallback } from 'react';
import axios from 'axios';

// use WindowSize
function useRequest() {
  // get credits ufs
  const getItemProductsCredit = useCallback(async (id) => {
    const items = await axios.get(`/api/v2/produtos/ufs?credito=${id}`)
      .then(({ data }) => {
        const items = data.sort((a, b) => {
          if (a.ordem === b.ordem) return 0;
          return a.ordem > b.ordem ? 1: -1;
        });
    
        return items.map(({ id, nome, sigla }) => ({ value: sigla, label: nome, id: id }));
      });

    return new Promise((resolve) => resolve(items));
  }, []);

  return {
    getItemProductsCredit,
  }
};

export default useRequest;