import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import ButtonScroll from '../ButtonScroll';
import WebdoorContent from './WebdoorContent';

import './webdoor.scss';

// Webdoor
const Webdoor = ({ value }) => {
  // element
  const element = useRef(null);

  // render
  return (
    <div className="webdoor" ref={element}>
      <WebdoorContent items={value} />

      <ButtonScroll className="webdoor--next" element={element}>
        <span className="icon icon-arrow-down"></span>
      </ButtonScroll>
    </div>
  );
};

Webdoor.propTypes = {
  any: PropTypes.any,
}

export default Webdoor;