import React from 'react';
import PropTypes from 'prop-types';

import './reports-subitem.scss';

// reports sub item
const ReportsSubItem = ({ extension, title, url }) => {
  // render
  return (
    <div className="reports--subitem">
      <div className="reports--subitem--info">
        <span className="icon icon-file"></span>

        <p className="document">
          <span className="name">{title}</span>
          <span className="extension">{extension}</span>
        </p>
      </div>

      <a className="btn btn-border t"
        href={`${process.env.REACT_APP_BASE_API_LECCA}${url}`} download>baixar</a>
    </div>);
};

ReportsSubItem.propTypes = {
  extension: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ReportsSubItem;