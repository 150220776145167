import React from 'react';
import PropTypes from 'prop-types';

import CategoriesTab from '../../CategoriesTab';
import ProductCreditsJuridicItem from '../ProductCreditsJuridicItem';

import './product-credits-juridic-content.scss';

// product credits juridic content
const ProductCreditsJuridicContent = ({ items, categories }) => {
  // render
  return (
    <div className="product-credits-juridic-content">
      <div className="wrapper">
        <CategoriesTab items={items} categories={categories} >
          {items && items.map((item, index) =>
            <ProductCreditsJuridicItem {...item} key={index} />)}
        </CategoriesTab>
      </div>
    </div>
  );
};

ProductCreditsJuridicContent.propTypes = {
  items: PropTypes.array,
  categories: PropTypes.array,
};

export default ProductCreditsJuridicContent;