import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import clamp from 'lodash-es/clamp';

import ListCarrouselPhotoControls from '../ListCarrouselPhotoControls';
import ListCarrouselPhotoItem from '../ListCarrouselPhotoItem';
import SliderBase from '../../../Slider/Base';

import './list-carrousel-photo-content.scss';

// list carrousel photo content
const ListCarrouselPhotoContent = ({ items, current, setCurrent, onPrevNext }) => {
  // element
  const element = useRef(null);

  // props set
  const [ props, set ] = useSprings(items.length, i => ({
    x: i * window.innerWidth,
    scale: 1,
    display: 'block'
  }));

  // drag
  const drag = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (down && distance > 200) {
      cancel((setCurrent(clamp(current + (xDir > 0 ? -1 : 1), 0, items.length - 1))));
    }
    
    set(i => {
      // width 
      const { width } = element.current || element.current instanceof Object ? element.current.getBoundingClientRect() : 0;

      if (i < current - 1) return { display: 'none' };

      const x = (i - current) * width + (down ? mx : 0);
      return { x, display: 'block' };
    });
  });

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      const init = () => {
        set(i => {
          const { width } = element.current || element.current instanceof Object ? element.current.getBoundingClientRect() : 0;

          if (i < current - 1) return { display: 'none' };
    
          const x = (i - current) * width;
          return { x, display: 'block' };
        });
      };

      init();
    }
  }, [ current, set ]);

  // render
  return (
    <div className="list-carrousel-photo-content" ref={element}>
      <ListCarrouselPhotoControls current={current} items={items.length} onPrevNext={onPrevNext} />

      <div className="list-carrousel-photo-content--list">
        {props.map(({ x, display }, i) => 
          <ListCarrouselPhotoItem item={items[i]} drag={drag} display={display} x={x} active={i === current} key={i} />)}
      </div>
    </div>
  );
};

ListCarrouselPhotoContent.propTypes = {
  items: PropTypes.array.isRequired,
  current: PropTypes.number,
  setCurrent: PropTypes.func.isRequired,
  onPrevNext: PropTypes.func.isRequired
};

export default SliderBase(ListCarrouselPhotoContent);