import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../../helpers/useLinkRefactory';

import LinkElement from '../../LinkElement';
import Slider from '../../Slider';
import WebdoorControls from '../WebdoorControls';

import './webdoor-info.scss';

// Webdoor info
const WebdoorInfo = ({ current, last, onPrevNext, items }) => {
  // link element
  const { linkElement } = useLinkRefactory();

  // render
  return (
    <div className="webdoor--info">
      <Slider current={current} last={last} onPrevNext={onPrevNext} type="item">
        {items && items.map((item, index) => {
          const link = linkElement(item);

          return <Fragment key={index}>
            <p className="title">{item.title}</p>
            {link && <LinkElement className="btn btn-border w" link={link}>{item.button_label}</LinkElement>}
          </Fragment>;
        })}
      </Slider>
      
      <WebdoorControls count={items.length} current={current} onPrevNext={onPrevNext} />
    </div>
  );
};

WebdoorInfo.propTypes = {
  current: PropTypes.number,
  last: PropTypes.number,
  items: PropTypes.array,
  onPrevNext: PropTypes.func.isRequired,
};

export default WebdoorInfo;