import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Parallax } from 'react-scroll-parallax';

import ListHighlightsList from './ListHighlightsList';
import Picture from '../../Picture';

import './list-highlights.scss';

// Card highlights
const ListHighlights = ({ value }) => {
  // props
  const { description, image_background, listitems, subtype, title } = value[0];

  // element
  const element = useRef(null);

  // render
  return (
    <div className="list-highlights" ref={element} data-type={subtype}>
      <div className="wrapper">
        <div className="list-highlights--info">
          <h1 className="title">{title}</h1>
          <p className="description">{description}</p>
        </div>

        <Parallax y={[subtype === 'tipo3' ? 0 : 10, 0]}>
          <ListHighlightsList element={element} items={listitems} type={subtype} />
        </Parallax>
      </div>

      <div className="list-highlights--image">
        {image_background && <Picture image={image_background} />}
      </div>
    </div>
  );
};

ListHighlights.propTypes = {
  value: PropTypes.array.isRequired,
};

export default ListHighlights;