
import React from 'react';
import PropTypes from 'prop-types';

import './carrousel-timeline-controls.scss';

// carrousel timeline controls
const CarrouselTimeLineControls = ({ current, onPrevNext, total }) => {
  // render
  return (
    <div className="carrousel-timeline--controls">
      <button className="btn btn-default d" onClick={() => onPrevNext('prev')} disabled={current === 0}>
        <span className="icon icon-arrow-left-l"></span>
      </button>
      <button className="btn btn-default d" onClick={() => onPrevNext('next')} disabled={current === (total - 1)}>
        <span className="icon icon-arrow-right-l"></span>
      </button>
    </div>
  );
};

CarrouselTimeLineControls.propTypes = {
  current: PropTypes.number.isRequired,
  onPrevNext: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

export default CarrouselTimeLineControls;