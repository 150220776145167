import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { animated as a } from 'react-spring';

import Picture from '../../../Picture';

import './list-carrousel-photo-item.scss';

// list carrousel photo item
const ListCarrouselPhotoItem = ({ active, drag, display, x, item: { image, abstract } }) => {
  // render
  return (
    <a.div
      {...drag()}
      className="list-carrousel-photo-item"
      data-active={active}
      style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }}>
      <div className="list-carrousel-photo-item--info">
        <Picture className="image" image={image}/>
        <p className="text">{abstract}</p>
      </div>
    </a.div>
  );
};

ListCarrouselPhotoItem.propTypes = {
  active: PropTypes.bool.isRequired,
  drag: PropTypes.func.isRequired,
  display: PropTypes.object,
  item: PropTypes.object.isRequired,
  x: PropTypes.object,
}

export default memo(ListCarrouselPhotoItem);
