import React from 'react';
import PropTypes from 'prop-types';

import ProductCreditsFisicItem from '../ProductCreditsFisicItem';

import './product-credits-fisic-content.scss';

// product credits fisic content
const ProductCreditsFisicContent = ({ items }) => {
  // render
  return (
    <div className="product-credits-fisic-content">
      <ul className="product-credits-fisic-content--list">
        {items && items.map((item, index) => 
          <li className="product-credits-fisic-content--item" key={index}>
            <ProductCreditsFisicItem {...item} />
          </li>)}
      </ul>
    </div>
  );
};

ProductCreditsFisicContent.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ProductCreditsFisicContent;