import React from 'react';
import PropTypes from 'prop-types';

import './title-text.scss';

// title text
const TitleText = ({ value }) => {
  // props
  const { body, title } = value;

  // render
  return (
    <div className="title-text">
      <div className="wrapper">
        <div className="title-text--content">
          {title && <h1 className="title-main">{title}</h1>}

          <div className="title-text--text" dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
    </div>
  );
};

TitleText.propTypes = {
  value: PropTypes.object.isRequired,
};

export default TitleText;