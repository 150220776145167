import React from 'react';
import PropTypes from 'prop-types';

import { Parallax } from 'react-scroll-parallax';

import './quote.scss';

// quote
const Quote = ({ value: { quote, subtitle, subtype, title } }) => {
  // render
  return (
    <div className="quote" data-type={subtype}>
      <div className="wrapper">
        <Parallax y={[10, 0]}>
          <div className="quote--content">
            <p className="quote--text">{quote}</p>

            {title && <p className="quote--title">
              <span className="text">{title}</span>
              <span className="subtitle">{subtitle}</span>
            </p>}
          </div>
        </Parallax>
      </div>

      <div className="quote--block">
        <span className="block"></span>
      </div>
    </div>
  );
};

Quote.propTypes = {
  value: PropTypes.object.isRequired,
};

export default Quote;