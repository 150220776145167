import React from 'react';
import PropTypes from 'prop-types';

import LinkElement from '../../LinkElement';
import { Parallax } from 'react-scroll-parallax';

import './photo-special-info.scss';

// photo special info
const PhotoSpecialInfo = ({ description, link, title }) => {
  // render
  return (
    <div className="photo-special--info">
      <Parallax y={[20, 0]}>
        <div className="photo-special--logo">
          <img src={`${process.env.PUBLIC_URL}images/logo-min.svg`} alt="foto" />
        </div>

        <h1 className="title">{title}</h1>
        <p className="description">{description}</p>

        {link && <LinkElement className="btn btn-border t" link={link}>{link.name}</LinkElement>}
      </Parallax>
    </div>
  );
};

PhotoSpecialInfo.propTypes = {
  description: PropTypes.string,
  link: PropTypes.object,
  title: PropTypes.string
}

export default PhotoSpecialInfo;