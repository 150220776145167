import React from 'react';
import PropTypes from 'prop-types';

import './text-highlight.scss';

// text highlight
const TextHighLight = ({ value: { body } }) => {
  // render
  return (
    <div className="text-highlight">
      <div className="wrapper">
        <div className="text-highlight--content" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  );
};

TextHighLight.propTypes = {
  value: PropTypes.object,
};

export default TextHighLight;