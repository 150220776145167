import React from 'react';
import PropTypes from 'prop-types';

// forms info
const FormsInfo = ({ title, body }) => {
  // render
  return (
    <div className="form--info">
      <picture>
        <source media="(min-width : 640px)" srcSet={`${process.env.PUBLIC_URL}/images/bg-form-type-1-md.jpg`} />
        <source media="(min-width : 320px)" srcSet={`${process.env.PUBLIC_URL}/images/bg-form-type-1-m.jpg`} />
        <img className="form--info--background" src={`${process.env.PUBLIC_URL}/images/bg-form.jpg`} alt="background" />
      </picture>

      <div className="form--info--content">
        <p className="title-main">{title}</p>
        <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  );
};

FormsInfo.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default FormsInfo;