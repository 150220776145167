import React from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../../../helpers/useLinkRefactory';

import LinkElement from '../../../LinkElement';
import Picture from '../../../Picture';

import './list-static-item.scss';

// list static item
const ListStaticItem = ({ item }) => {
  // link
  const { linkElement } = useLinkRefactory();

  // props
  const { icone, description, title1, title2, cta_label } = item;
  const link = linkElement(item);

  // render
  return (
    <li className="list-static--item">
      <div className="block-icon">
        <Picture className="icone" image={icone} />
      </div>
      
      <p className="title">{title1}</p>
      <p className="title">{title2}</p>
      <p className="description">{description}</p>

      {link && <LinkElement className="link" link={link}>{cta_label}</LinkElement>}
    </li>
  );
};

ListStaticItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ListStaticItem;