import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CategoriesTabContentItemSlider from './CategoriesTabContentItemSlider';

import './categories-tab-content-item.scss';

// categories tab content item
const CategoriesTabContentItem = ({ current, item, onSelect, items }) => {
  // render
  return (
    <Fragment>
      <p className="categories-tab-content-item--name">{item.nome}</p>
      
      <ul className="categories-tab-content-item--list">
        {items && items.map(({ item }, key) =>
          <li className="categories-tab-content-item--list--item" key={key}>
            <button className="btn btn-tab"
              data-active={current === item.index}
              onClick={() => onSelect(item)}>{item.nome}</button>
          </li>)}
      </ul>

      <CategoriesTabContentItemSlider items={items} />
    </Fragment>
  );
};

CategoriesTabContentItem.propTypes = {
  current: PropTypes.number,
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  items: PropTypes.array,
};

export default CategoriesTabContentItem;