import React from 'react';
import PropTypes from 'prop-types';

import './footer-networks.scss';

// footer networks
const FooterNetworks = ({ items }) => {
  // render
  return (
    <ul className="footer--networks">
      {items && items.map(({ url, icone: { thumbnail: { src, alt } } }, index) =>
        <li className="footer--networks--item" key={index}>
          <a className="link" href={url} target="_blank" rel="noopener noreferrer">
            <img className="icon" src={`${process.env.REACT_APP_BASE_API_LECCA}${src}`} alt={alt} />
          </a>
        </li>)}
    </ul>
  );
};

FooterNetworks.propTypes = {
  items: PropTypes.array.isRequired,
}

export default FooterNetworks;