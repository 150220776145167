import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProductCreditsFisicContentGalleryControls from '../ProductCreditsFisicContentGalleryControls';
import ProductCreditsFisicContentGalleryItem from '../ProductCreditsFisicContentGalleryItem';
import SliderBase from '../../../Slider/Base';
import SliderGallery from '../../../Slider/Gallery';

import './product-credits-fisic-content-gallery.scss';

// product credits fisic content gallery
const ProductCreditsFisicContentGallery = ({ current, city, items, onPrevNext, setCurrent, setItem }) => {
  // width
  const [ width, setWidth ] = useState(265);

  // use effect
  useEffect(() => {
    if (current) {
      if (window.innerWidth <= 768) {
        setWidth(240);
      } else {
        setWidth(265);
      }
    }
  }, [ current ]);

  // render
  return (
    <div className="product-credits-fisic-item-content-gallery">
      <div className="wrapper">
        <ProductCreditsFisicContentGalleryControls
          count={items.length}
          current={current}
          setItem={setItem}
          onPrevNext={onPrevNext} />
        
        {items && <SliderGallery
          className="product-credits-fisic-item-content-gallery"
          current={current}
          type={'scroll'}
          onPrevNext={onPrevNext}
          width={width}>
          {items.map((item, index) =>
            <ProductCreditsFisicContentGalleryItem
              {...item}
              setCurrent={setCurrent}
              index={index}
              city={city}
              width={width}
              key={index} />)}
        </SliderGallery>}
      </div>
    </div>
  );
};

ProductCreditsFisicContentGallery.propTypes = {
  items: PropTypes.array
}

export default SliderBase(ProductCreditsFisicContentGallery);