import React, { memo, useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectItem from './SelectItem';
import SelectTitle from './SelectTitle';

import './select.scss';

// select
const Select = ({ defaultText, handleChange, options, selectedOption, type }) => {
  // active
  const [ active, setActive ] = useState(false);
  // element
  const element = useRef(null);

  // check element
  const checkElement = useCallback(event => {
    if (element.current instanceof Object && event instanceof Object) {
      const { x, y } = event;
      const { height, left, top, width } = element.current.getBoundingClientRect();

      const maxHeight = height + top;
      const maxWidth = left + width;

      if ((x < left || x > maxWidth) || (y < top || y > maxHeight)) {
        setActive(false);
      }
    }
  }, [ setActive ]);

  // on change
  const onChange = useCallback(item => {
    setActive(false);
    handleChange(item);
  }, [ handleChange, setActive ]);

  // on toggle
  const onToggle = useCallback((active) => {
    setActive(active);
  }, [ setActive ]);

  // use effect
  useEffect(() => {
    if (active === true) {
      window.addEventListener('click', checkElement);
    } else {
      window.removeEventListener('click', checkElement);
    }

    return () => {
      window.removeEventListener('click', checkElement);
    };
  }, [ active, checkElement ]);

  // render
  return (
    <div className="select" data-active={active} data-type={type} ref={element}>
      <SelectTitle active={active} defaultText={defaultText} onToggle={onToggle} selectedOption={selectedOption} />

      {options && <ul className="select--list">
        {options.map((item, index) =>
          <li className="select--list--item" key={index}>
            <SelectItem item={item} onSelectOption={onChange} selectedOption={selectedOption} />
          </li>)}
      </ul>}
    </div>
  );
};

Select.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.any,
  type: PropTypes.string,
};

export default memo(Select);