import React, { memo, useState } from 'react';

import axios from 'axios';
import { Formik, Form } from 'formik';

import useForm from '../../../helpers/useForm';

import FormsInfo from './FormsInfo';
import Input from '../Input';

// form credit juridic
const FormInvestment = ({ value: { title, body }}) => {
  // state
  const [ error, setError ] = useState(false);
  // use form
  const { checkValue, checkNumber } = useForm();

  // initial
  const initialValues = {
    valor: 0,
    unidade_tempo: 'meses',
    tempo: '',
    nome: '',
    ddd: '',
    telefone: '',
    email: '',
    informacoes_adicionais: '',
    aceita_receber_comunicados: 'false'
  };

  // render
  return (
    <div className="form" data-error={error}>
      <div className="wrapper">
        <FormsInfo body={body} title={title} />

        <div className="form--inputs">
          <Formik
            initialValues={initialValues}
            initialStatus={{ success: false }}

            // validate
            validate={values => {
              const errors = {};
              const { valor, tempo, nome, ddd, telefone, email, informacoes_adicionais } = values;

              if (valor <= 0 || valor < 5000)
                errors.valor = true;

              if (tempo !== '' && !checkValue(tempo, 'number'))
                errors.tempo = true;

              if (nome !== '' && !checkValue(nome, 'text'))
                errors.nome = true;

              if (ddd !== '' && checkNumber(ddd) <= 0)
                errors.ddd = true;

              if (telefone !== '' && !checkValue(telefone, 'phone'))
                errors.telefone = true;
              
              if (email !== '' && !checkValue(email, 'email'))
                errors.email = true;

              if (!informacoes_adicionais)
                errors.informacoes_adicionais = true;

              setError(Object.keys(errors).length > 0);
              
              return errors;
            }}

            // on submit
            onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
              setStatus({ success: false });

              const params = values;
              params.valor = Number(params.valor.toString().replace(/[^0-9\\.]+/g, ''));
              params.telefone = params.ddd.toString() + params.telefone.toString();

              await axios.post('/api/v2/formularios/investimento', {
                ...params
              }).then(e => setStatus({ success: true }));
              
              resetForm(initialValues);
              setSubmitting(false);
            }}>
            {({
              errors,
              status,
              values,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form autoComplete="off">
                <div className="input-group two">
                  <Input
                    label="Quanto você gostaria de investir?"
                    name="valor"
                    type="money"
                    maxLength={9999999999999}
                    info="Valor mínimo: R$ 5.000,00"
                    setFieldValue={setFieldValue}
                    error={errors.valor}
                    required={true} />

                  <Input label="Por quanto tempo?" name="tempo" type="text" error={errors.tempo} required={true} />

                  <div className="input-group radios r">
                    <Input label="Meses" name="unidade_tempo" type="radio" value="meses"
                      checked={values.unidade_tempo === 'meses'} setFieldValue={setFieldValue} required={true} />
                    <Input label="Anos" name="unidade_tempo" type="radio" value="anos"
                      checked={values.unidade_tempo === 'anos'} setFieldValue={setFieldValue} required={true} />
                  </div>
                </div>

                <div className="input-group two">
                  <Input label="Nome" name="nome" type="text" error={errors.nome} required={true} />

                  <div className="input-group join" data-error={errors.ddd || errors.telefone}>
                    <label className="label">telefone</label>
                    <Input label="" name="ddd" type="ddd" error={errors.ddd} required={true} />
                    <Input label="" name="telefone" type="phone" error={errors.telefone} required={true} />
                  </div>
                </div>

                <div className="input-group two">
                  <Input label="Email" name="email" type="email" error={errors.email} required={true} />
                </div>

                <div className="input-group">
                  <Input
                    label="Informações adicionais"
                    name="informacoes_adicionais"
                    type="textarea"
                    info="600 caracteres restantes"
                    error={errors.informacoes_adicionais} />
                </div>
                
                {!status.success &&
                  <button
                    className="btn btn-border w"
                    type="submit"
                    disabled={isSubmitting}>enviar</button>}
                
                {status.success === true &&
                  <div className="input-message">
                    <p className="message">
                      <span className="title">Obrigado por entrar em contato conosco.</span>
                      <span className="description">Sua mensagem será direcionada para a equipe responsável e logo entraremos em contato com você.</span>
                    </p>
                  </div>}

                <div className="input-group">
                  <Input
                    label="Concordo receber futuros comunicados da Lecca"
                    name="aceita_receber_comunicados"
                    type="check" />
                </div>
            </Form>)}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default memo(FormInvestment);