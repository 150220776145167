import React from 'react';
import PropTypes from 'prop-types';

import CategoriesTabContent from './CategoriesTabContent';
import CategoriesTabCategories from './CategoriesTabCategories';
import SliderBase from '../Slider/Base';

import './categories-tab.scss';

// categories tab
const CategoriesTab = ({ categories, children, current, items, setCurrent }) => {
  // render
  return (
    <div className="categories-tab">
      <CategoriesTabCategories
        current={current}
        children={children}
        categories={categories}
        items={items}
        setCurrent={setCurrent} />
      
        <CategoriesTabContent 
          current={current}
          children={children}
          item={items[current]} />
    </div>
  );
};

CategoriesTab.propTypes = {
  categories: PropTypes.array,
  children: PropTypes.array,
  current: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default SliderBase(CategoriesTab);