import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePortals } from 'react-portal-hook';
import ReactPlayer from 'react-player';

import Modal from '../../../Modal';
import Picture from '../../../Picture';

import './list-carrousel-video-item.scss';

// list carrousel video item
const ListCarrouselVideoItem = ({ abstract, image, title, url }) => {
  // portal use
  const portalManager = usePortals();
  const [ duration, setDuration ] = useState(0);
 
  // open modal
  const openModal = () => {
    portalManager.open(portal => <Modal closeModal={portal.close}>
      <div className="modal-youtube">
        <ReactPlayer url={url} onDuration={d => setDuration(d)} />
      </div>
    </Modal>);
  };

  // get format
  const getFormat = duration => {
    if (duration <= 0) return false;

    const totalSeconds = duration %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = duration % 60;

    return `${minutes <= 9 ? `0${minutes}` : minutes }:${ seconds <= 9 ? `0${seconds}` : seconds }`;
  };

  // render
  return (
    <div className="list-carrousel-video-item" onClick={openModal}>
      <div className="list-carrousel-video-item--image">
        <Picture image={image} />
      </div>

      <div className="list-carrousel-video-item--content">
        <p className="title">{title}</p>
        <p className="description">{abstract}</p>

        <p className="duration">{getFormat(duration)}</p>
      </div>
    </div>
  );
};

ListCarrouselVideoItem.propTypes = {
  abstract: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default ListCarrouselVideoItem;