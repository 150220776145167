import React, { memo, useState, useEffect } from 'react';
import Proptypes from 'prop-types';

import useRequest from '../../../../helpers/useRequest';

import Select from '../../../Select';

import './product-credits-fisic-item-select.scss';

// product credits fisic item select
const ProductCreditsFisicItemSelect = ({ id, description, onChange, item }) => {
  // state
  const [ items, setItems ] = useState([]);
  const { getItemProductsCredit } = useRequest();

  // usee effect
  useEffect(() => {
    if (id) {
      getItemProductsCredit(id)
        .then(data => setItems(data));
    }
  }, [ id, getItemProductsCredit ]);

  // render
  return (
    <div className="product-credits-fisic-item--select">
      <div
        className="product-credits-fisic-item--select--description"
        dangerouslySetInnerHTML={{ __html: description }}></div>

      <div className="product-credits-fisic-item--select--form">
        <p className="title">Como contratar?</p>
        <p className="description">Selecione seu estado e encontre um correspondente bancário</p>

        {Array.isArray(items) &&
          <Select
            defaultText="Selecione um estado"
            options={items}
            handleChange={onChange}
            selectedOption={item} />}
      </div>
    </div>
  );
};

ProductCreditsFisicItemSelect.propTypes = {
  description: Proptypes.string,
  id: Proptypes.number,
  onChange: Proptypes.func.isRequired,
  item: Proptypes.object,
};

export default memo(ProductCreditsFisicItemSelect);