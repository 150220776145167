import React from 'react';
import PropTypes from 'prop-types';

import ListContentItem from '../ListContentItem';

import './list-content-list.scss';

// list content
const ListContentList = ({ items, type }) => {
  // render
  return (
    <ul className="list-content-list" data-type={type}>
      {items && items.map((item, index) =>
        <li className="list-content-list--item" key={index}>
          <ListContentItem {...item} />
        </li>)}
    </ul>
  );
};

ListContentList.propTypes = {
  items: PropTypes.array.isRequired,
  type: PropTypes.string,
};

export default ListContentList;