import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import { useSpring, animated as a } from 'react-spring';

import './alert-button.scss';

// Alert Button
const AlertButton = () => {
  // values
  const [ values, setValues ] = useState();
  const [ active, setActive ] = useState(true);

  // ani
  const styles = useSpring({ transform: active ? 'translateY(0)' : 'translateY(100%)' });

  // use effect
  useEffect(() => {
    const api = async () => {
      const { data } = await axios.get('/api/v2/fragmentos/alertas');
      
      if (data) {
        setValues(data[0]);
      }
    };

    api();
  }, []);

  // render
  return (
    <div className="alert-button" data-active={active}>
      {values instanceof Object && <div className="alert-button--top">
        <div className="wrapper">
          <button className="btn btn-border t" onClick={() => setActive(!active)}>
            <span className="icon icon-light"></span>{values.titulo}
          </button>
        </div>
      </div>}

      {values instanceof Object && <a.div className="alert-button--content" style={styles}>
          <div className="wrapper">
            <p className="title"><span className="icon icon-light"></span>{values.titulo}</p>
            <p className="description" dangerouslySetInnerHTML={{ __html: values.corpo }} />
            <p className="message">{values.descricao}</p>
            
            <button className="btn btn-default b" onClick={() => setActive(!active)}>Ok</button>
          </div>
        </a.div>}
    </div>
  );
};

AlertButton.propTypes = {
  any: PropTypes.any,
};

export default AlertButton;