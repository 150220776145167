import React, { createElement, memo, useEffect, useContext } from 'react';

import { PageContext, TYPE_COMPONENTS } from '../../providers/PageProvider';

import TabSubMenu from '../../components/TabSubMenu';
import WebdoorInternal from '../../components/WebdoorInternal';

// Page
const Page = ({ location, page }) => {
  // page context
  const pageContext = useContext(PageContext);
  const { route, loadPage } = pageContext;

  // load page
  const { corpo } = page;

  // find element
  const findElement = type => TYPE_COMPONENTS.filter(item => type === item.type)[0];

  // create element
  const createElementPage = (element, index) => {
    if (element instanceof Object) {
      const { type, value } = element;

      if (value instanceof Object) {
        const component = findElement(type);

        if (component instanceof Object) {
          const { componentElement } = component;

          return createElement(componentElement, { key: index, type, value, });
        }
      }
    }

    return false;
  };

  // use effect
  useEffect(() => {
    if (route instanceof Object && route.id) {
      loadPage(route);
    }
  }, [ route, loadPage ]);

  // render
  return (
    <div className="page">
      <WebdoorInternal location={location} />
      <TabSubMenu location={location} />

      {corpo && corpo.map((item, index) => createElementPage(item, index))}
    </div>
  )
};

export default memo(Page);
