import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import IntlCurrencyInput from 'react-intl-currency-input';
import InputMask from 'react-input-mask';

import useForm from '../../../helpers/useForm';

import './input.scss';

// Input
const Input = ({ maxLength, required, name, label, type, info, error, value, checked, setFieldValue, theme }) => {
  // use form
  const { formatMoney } = useForm();

  // get input type
  const getInputType = () => {
    switch (type) {
      case 'ddd':
        return <Field name={name}>{({ field }) =>
          <InputMask {...field} mask="999" name={name} required={required} />}
        </Field>;

      case 'phone':
        return <Field name={name}>{({ field }) =>
          <input {...field} type="tel" name={name} required={required} />}
        </Field>;

      case 'cnpj':
        return <Field name={name}>{({ field }) =>
          <InputMask
            {...field}
            name={name}
            mask="99.999.999/9999-99"
            required={required} />}
          </Field>;

      case 'check':
        return <Fragment>
          <Field
            name={name}
            checked={value}
            type="checkbox" 
            required={required} />
          <label className="icon icon-check"></label>
        </Fragment>;
          
      case 'email':
        return <Field
          name={name}
          type="text" 
          required={required} />;

      case 'radio':
        return <Fragment>
          <input
            type="radio"
            id={name+value}
            name={name}
            checked={checked}
            onChange={() => setFieldValue(name, value)} 
            required={required} />
          <label className="radio-icon" htmlFor={name+value}></label>
        </Fragment>;

      case 'textarea':
        return <Fragment>
          <Field
            rows="7"
            name={name}
            component="textarea"
            required={required} />
          <label className="info">600 caracteres restantes</label>
        </Fragment>;

      case 'money':
        return <Field name={name}>{({ field }) =>
          <Fragment>
            <IntlCurrencyInput
              {...field}
              onChange={(event, value) => {
                setFieldValue(name, value);
              }}
              max={maxLength}
              currency="BRL"
              config={formatMoney} 
              required={required} />
            <label className="info">{info}</label>
          </Fragment>}
        </Field>;

      case 'text':
      default:
        return <Field
          type="text"
          name={name}
          maxLength={maxLength}
          required={required} />;
    }
  };

  // render
  return (
    <label className="input-control" data-error={error} data-type={type} data-theme={theme}>
      <label className="label">{label}</label>
      {getInputType()}
    </label>
  );
};

Input.propTypes = {
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  info: PropTypes.string,
  error: PropTypes.bool,
}

export default memo(Input);