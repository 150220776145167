import React, { memo, useCallback, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

import { isMobile } from 'react-device-detect';

import useNavigation from '../../../helpers/useNavigation';
import HeaderMenuItem from '../HeaderMenuItem';

import './header-menu.scss';

// Header Menu
const HeaderMenu = ({ location, routes, route, open, setOpen }) => {
  // state
  const [ openId, setOpenId ] = useState(0);

  // filter routes
  const { getMenuMain } = useNavigation();
  // ref
  const element = useRef(null);

  // get element
  const getElement = (item, index) => {
    if (item.submenu instanceof Object && item.submenu.length > 0) {
      return <HeaderMenuItem {...item} onMobileOpen={setOpenId} active={openId} index={index} />;
    } else {
      return <NavLink className="link link-default" to={`/${item.meta.slug}`}>
          <span className="name">{item.title}</span>
        </NavLink>;
    }
  };

  // click out
  const clickOut = useCallback((event) => {
    if (isMobile && open) {
      if (element.current instanceof Object) {
        const props = element.current.getBoundingClientRect();

        if (event.x > props.width) {
          setOpen(false);
        }
      }
    }
  }, [ open, setOpen ]);

  // get parent router
  const parentRouter = useCallback(item => {
    const { pathname } = location;

    if (item instanceof Object && pathname) {
      const { meta: { slug } } = item;
      const route = pathname.substring(1).split('/');

      return slug === route[0];
    }

    return false;
  }, [ location ]);

  // use effect
  useEffect(() => {
    if (element.current) {
      window.addEventListener('click', clickOut);
    }

    return () => {
      window.removeEventListener('click', clickOut);
    };
  });
  
  // render
  return (
    <ul className="header--menu" ref={element}>
      {routes && getMenuMain(routes).map((item, key) => <li
        className="header--menu--item"
        data-active={item === route || parentRouter(item)}
        data-open={item.id === openId}
        data-submenu={item.submenu instanceof Object && item.submenu.length > 0 ? 'true' : 'false'}
        key={key}>
        {getElement(item, item.id)}
      </li>)}

      {isMobile && <li className="header--menu--item last">
        <Link className="link link-default" to={'/carreiras'}>
          <span className="name">Carreiras</span>
        </Link>
      </li>}
    </ul>
  );
};

HeaderMenu.propTypes = {
  location: PropTypes.object,
  routes: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
};

export default memo(HeaderMenu);