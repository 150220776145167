import React from 'react';
import PropTypes from 'prop-types';

import './carrousel-timeline-time-item.scss';

// carrousel timeline item
const CarrouselTimeLineTimeItem = ({ active, current, year }) => {
  return (
    <div className="carrousel-timeline-time-item" data-current={current} data-active={active}>
      <p className="year">{year}</p>
    </div>
  );
};

CarrouselTimeLineTimeItem.propTypes = {
  active: PropTypes.bool,
  year: PropTypes.string,
}

export default CarrouselTimeLineTimeItem;