import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import './header-menu-item.scss';

// Header menu item
const HeaderMenuItem = ({ active, id, meta, title, retranca, submenu, onMobileOpen }) => {
  // render
  return (
    <Fragment>
      {!isMobile && <NavLink
        className="link link-default"
        to={`/${meta.slug}`}>
        <span className="name">{title}</span>
        <span className="icon icon-arrow-down"></span>
        <span className="icon icon-arrow-top"></span>
      </NavLink>}

      {isMobile && retranca && <p
        className="link link-default" onClick={() => onMobileOpen(active !== id ? id : 0)}>
          <span className="name">{title}</span>
          <span className="icon icon-arrow-down"></span>
          <span className="icon icon-arrow-top"></span>
        </p>}

      <ul className="header--menu--submenu">
        {submenu instanceof Object && submenu.map((subitem, subIndex) =>
          <li
            className="header--menu--submenu--item"
            key={subIndex}>
            <NavLink
              className="link link-default" to={`/${subitem.meta.slug}`}>
              <span className="name">{subitem.title}</span>
            </NavLink>
          </li>
        )}
      </ul>
    </Fragment>
  );
};

HeaderMenuItem.propTypes = {
  active: PropTypes.number,
  id: PropTypes.number,
  meta: PropTypes.object,
  retranca: PropTypes.bool,
  title: PropTypes.string,
  onMobileOpen: PropTypes.func,
}

export default memo(HeaderMenuItem);