import React from 'react';
import PropTypes from 'prop-types';

import { animated as a } from 'react-spring';

import Picture from '../../Picture';

import './webdoor-item.scss';

// Webdoor item
const WebdoorItem = ({ display, drag, i, items, scale, x }) => {
  // render
  return (
    <a.div
      {...drag()}
      className="webdoor--item"
      key={i}
      style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }}>
      <a.div style={{ scale }}>
        <Picture image={items[i].image} />
      </a.div>
    </a.div>
  );
};

WebdoorItem.propTypes = {
  display: PropTypes.object,
  drag: PropTypes.func.isRequired,
  i: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  scale: PropTypes.object.isRequired,
  x: PropTypes.object,
};

export default WebdoorItem;