import React from 'react';
import PropTypes from 'prop-types';

import './mission-vision-values-item.scss';

// mission vision values item
const MissionVisionValuesItem = ({ body, title, type }) => {
  // render
  return (
    <div className="mission-vision-values--item" data-type={type}>
      <p className="title">{title}</p>
      <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
    </div>
  );
};

MissionVisionValuesItem.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.number.isRequired,
};

export default MissionVisionValuesItem;