import React from 'react';
import PropTypes from 'prop-types';

import ReportsList from './ReportsList';

import './reports.scss';

// reports
const Reports = ({ value: { title, listitems } }) => {
  // render
  return (
    <div className="reports">
      <div className="wrapper">
        <div className="reports--container">
          {title && <h1 className="title-main">{title}</h1>}
          {listitems && <ReportsList items={listitems} />}
        </div>
      </div>
    </div>
  );
};

Reports.propTypes = {
  value: PropTypes.object.isRequired,
};

export default Reports;