import React, { createContext, useCallback, useState, useEffect } from 'react';
import axios from 'axios';

import useLocalStorage from '../../helpers/useLocalStorage';

// footer context
const FooterContext = createContext({
  address: [],
  networks: [],
});

// alert provider
const FooterProvider = ({ children }) => {
  // use local
  const { checkHours, localStorageItem, setValueFunction, setValue } = 
    useLocalStorage('lecca_footer', { address: [], networks: [], date: Date.now() });

  // data
  const [ state, setState ] = useState({
    address: [],
    networks: [],
  });

  // set footer
  const setFooter = useCallback((address, networks) => {
    if (Array.isArray(address) === false || Array.isArray(networks) === false) return false;

    setState({ address, networks });
  }, []);

  // get footer
  const getFooter = useCallback(async (setValue, setValueFunction) => {
    const address = await axios.get(`/api/v2/fragmentos/enderecos`).then(res => res.data);
    const networks = await axios.get(`/api/v2/fragmentos/redes-sociais`).then(res => res.data);

    return setValue('lecca_footer', { address, networks, date: Date.now() }, setValueFunction)
      .then(() => setFooter(address, networks));
  }, [ setFooter ]);

  // load
  const load = useCallback((setValue, setValueFunction) => {
    const { address, networks, date } = localStorageItem;
    
    if (address.length > 0 && networks.length > 0 && checkHours(date) < process.env.REACT_APP_LOCALSTORAGE_TIME) {
      setFooter(address, networks);
    } else {
      getFooter(setValue, setValueFunction);
    }
  }, [ localStorageItem, setFooter, checkHours, getFooter ]);

  // use effect
  useEffect(() => {
    load(setValue, setValueFunction);
  }, [ load, setValue, setValueFunction ]);

  // render
  return (
    <FooterContext.Provider
      value={{
        ...state,
      }}>
      {children}
    </FooterContext.Provider>
  );
};

export { FooterContext, FooterProvider };
export default FooterProvider;