import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { MainProvider } from './providers/MainProvider';
import { PortalProvider } from "react-portal-hook";

import axios from 'axios';
import * as serviceWorker from './serviceWorker';

import Main from './layout/Main';

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_LECCA;

axios.interceptors.request.use(request => request, error => Promise.reject(error));
axios.interceptors.response.use(response => response, error => Promise.reject(error));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <PortalProvider>
        <MainProvider>
          <Main />
        </MainProvider>
      </PortalProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
