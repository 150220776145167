import React from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../helpers/useLinkRefactory';

import PhotoSpecialImage from './PhotoSpecialImage';
import PhotoSpecialInfo from './PhotoSpecialInfo';

import './photo-special.scss';

// Photo Special
const PhotoSpecial = ({ value }) => {
  // link element
  const { linkElement } = useLinkRefactory();

  // props
  const { description, image, subtitle, title } = value;
  const link = linkElement(value);

  // render
  return (
    <div className="photo-special">
      <div className="wrapper">
        <PhotoSpecialImage image={image} subtitle={subtitle} />
        <PhotoSpecialInfo description={description} link={link} title={title} />
      </div>

      <div className="photo-special--block">
        <span className="block"></span>
      </div>
    </div>
  );
};

PhotoSpecial.propTypes = {
  value: PropTypes.object.isRequired,
};

export default PhotoSpecial;