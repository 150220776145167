import React, { createContext, useCallback, useState, useRef } from 'react';
import axios from 'axios';

import { TYPE_COMPONENTS } from './components';

// Page context
const PageContext = createContext({
  page: {},
  setPage: () => {},
});

// Page provider
const PageProvider = ({ children }) => {
  // cancel token
  const CancelToken = axios.CancelToken;
  const cancel = useRef(undefined);

  // page
  const [ page, setPage ] = useState({});

  // load page
  const loadPage = useCallback(async (route) => {
    if (route instanceof Object === false) return false;

    if (cancel.current !== undefined) {
      const cancelRequest = cancel.current;
      cancelRequest();
    }

    await axios({
      url: `/api/v2/pages/${route.id}`,
      method: 'GET',
      cancelToken: new CancelToken(c => cancel.current = c)
    }).then(res => {
      const data = res.data;
      
      if (data) {
        setPage(data);
      }
    }).catch((thrown) => {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      }
    });
  }, [ setPage, CancelToken ]);

  // render
  return (
    <PageContext.Provider
      value={{
        page,
        loadPage,
      }}>
      {children}
    </PageContext.Provider>
  );
};

export { PageContext, PageProvider, TYPE_COMPONENTS };
export default PageProvider;