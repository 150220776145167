import React, { useContext } from 'react';
import PropTypes from 'prop-types';

//http://lecca.homolog.sirius.com.br/o-grupo/sobre-lecca -- no aprovado
//http://lecca.homolog.sirius.com.br/o-grupo/governanca-corporativa
//http://lecca.homolog.sirius.com.br/o-grupo/informacoes-financeiras
import { FooterContext } from '../../../providers/FooterProvider';

import FooterNetworks from '../FooterNetworks';

import './footer-bottom.scss';

// Footer Bottom
const FooterBottom = ({ list }) => {
  // context
  const footerContext = useContext(FooterContext);
  const { networks } = footerContext;

  // render
  return (
    <div className="footer--bottom">
      <div className="wrapper">
        <FooterNetworks items={networks} />

        <ul className="footer--bottom--list">
          {list && list.map(({ href, name }, index) =>
            <li className="footer--bottom--list--item" key={index}>
              <a className="link" href={href}>{name}</a>
            </li>)}
        </ul>

        <div className="footer--credits">
          <a className="link" href="https://www.sirius.com.br" target="_blank" rel="noopener noreferrer">
            <img className="image" src={`${process.env.PUBLIC_URL}/images/logo-sirius-footer.png`} alt="Sirius Ltda" />
            <span className="text">Desenvovido pela Sirius Interativa</span>
          </a>
        </div>
      </div>
    </div>
  );
};

FooterBottom.propTypes = {
  list: PropTypes.array.isRequired,
};

export default FooterBottom;