import React from 'react';
import PropTypes from 'prop-types';

import Picture from '../Picture';

import './bank-as-service-hightlight.scss'

// bank as service hightlight
const BankAsaServiceHighlight = ({ value: { description1, description2, description3, image1, image2, image3, title1, title2, title3 }}) => {
  // render
  return (
    <div className="bank-as-service-hightlight">
      <div className="bank-as-service-hightlight--content">
        <div className="bank-as-service-hightlight--item">
          <Picture className="image" image={image1} />

          <p className="title">{title1}</p>
          <p className="description">{description1}</p>
        </div>

        <div className="bank-as-service-hightlight--item">
          <Picture className="image" image={image2} />

          <p className="title">{title2}</p>
          <p className="description">{description2}</p>
        </div>

        <div className="bank-as-service-hightlight--item">
          <Picture className="image" image={image3} />

          <p className="title">{title3}</p>
          <p className="description">{description3}</p>
        </div>
      </div>
    </div>
  );
};

BankAsaServiceHighlight.propTypes = {
  value: PropTypes.object.isRequired,
};

export default BankAsaServiceHighlight;