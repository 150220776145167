// use WindowSize
function useForm() {
  // check value
  const checkValue = (value, type) => {
    let regex = null;

    switch (type) {
      case 'email':
        regex = new RegExp('^[a-z0-9]+(\\.[_a-z0-9]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,15})$', 'i');
      break;
      case 'cnpj':
        regex = new RegExp('[0-9]{2}\\.?[0-9]{3}\\.?[0-9]{3}\\/?[0-9]{4}\\-?[0-9]{2}', 'g');
      break;
      case 'number':
        regex = new RegExp('^[0-9]+$');
      break;
      case 'money':
        regex = new RegExp('^(\\$?(:?\\d+,?)+(?:.?\\d+)?)$', 'g');
      break;
      case 'text':
        regex = new RegExp('^[A-Za-z\\s]+$', 'g');
      break;
      case 'phone':
        regex = new RegExp('^\\s*(\\d{2}|\\d{0})[-. ]?(\\d{5}|\\d{4})[-. ]?(\\d{4})[-. ]?\\s*$' ,'g');
      break;
      case 'textarea':
        regex = new RegExp('^.{6,600}$');
      break;
      default:
      case 'normal':
        regex = null;
    }
    
    return regex.test(value);
  };

  // number check
  const checkNumber = (value) => {
    return Number(value.toString().replace(/[^0-9\\.]+/g, ''));
  }

  // format money
  const formatMoney = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  return {
    checkValue,
    formatMoney,
    checkNumber,
  };
}

export default useForm;