import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import SliderBase from '../../Slider/Base';
import Slider from '../../Slider/Slider';
import Picture from '../../Picture';

import './text-slider-highlight-gallery.scss';

// text slider high light gallery
const TextSliderHighLightGallery = ({ current, setCurrent, items }) => {
  // element
  const element = useRef(null);

  // render
  return (
    <div className="text-slider-highlight-gallery" ref={element}>
      <div className="text-slider-highlight-gallery--controls">
        {items && items.map((item, key) =>
          <button
            className="text-slider-highlight-gallery--controls-btn"
            key={key}
            data-active={current === key}
            onClick={() => setCurrent(key)}></button>)}
      </div>

      <Slider
        current={current}
        className="text-slider-highlight-gallery"
        items={items}
        setCurrent={setCurrent}
        width={element.current ? element.current.getBoundingClientRect().width : 0}>
        {items && items.map(({ image }, key) =>
          <Picture className="background" image={image} key={key} />)}
      </Slider>
    </div>
  );
};

TextSliderHighLightGallery.propTypes = {
  current: PropTypes.number.isRequired,
  items: PropTypes.array,
  setCurrent: PropTypes.func.isRequired,
};

export default SliderBase(TextSliderHighLightGallery);