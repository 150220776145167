import React, { Fragment, memo, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { NavContext } from '../../providers/NavProvider';

import './webdoor-internal.scss';
import useNavigation from '../../helpers/useNavigation';

// Webdoor internal
const WebdoorInternal = ({ location }) => {
  // context
  const navContext = useContext(NavContext);
  const { routes, route } = navContext;

  const { getParent, routeParentSubmenu } = useNavigation();

    // check active
  const createTabSubMenu = () => {
    const { pathname } = location;
    const routeLenght = pathname.substring(1).split('/');

    return routeLenght.length > 0 && pathname !== '/' ? true : false;
  };

  // title
  const title = useCallback(() => {
    if (route instanceof Object === false || !route.meta) return false;

    if (route.meta.slug.split('/').length <= 2) {
      if (!route.submenu) {
        const parent = routeParentSubmenu(route, routes);
  
        if (Array.isArray(parent) && parent.length > 0) {
          return parent[0].title;
        }
      }
  
      return route.title;
    } else {
      const parent = getParent(route, routes);
      return parent.title;
    }
  }, [ route, routes, getParent, routeParentSubmenu ]);

  // render
  return (
    <div className="webdoor-internal">
      {createTabSubMenu() && 
        <Fragment>
          <div className="webdoor-internal--background">
            <img src={`/images/bg-webdoor-internal.jpg`} alt="background" />
          </div>
          <h1 className="webdoor-internal--title">{title()}</h1>
        </Fragment>}
    </div>
  );
};

WebdoorInternal.propTypes = {
  location: PropTypes.object,
};

export default memo(WebdoorInternal);