import React, { memo } from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../../../helpers/useLinkRefactory';

import LinkElement from '../../../LinkElement';
import Picture from '../../../Picture';

import './list-content-item.scss';

// list content
const ListContentItem = item => {
  // link
  const { linkElement } = useLinkRefactory();

  // props
  const { description, image, pre_title, title, params } = item;
  const link = linkElement(item);

  // get link
  const getLink = (link, params) => {
    if (params) {
      link.url = `${link.url}?select_option=${params}`;
      return link;
    }

    return link;
  };

  // render
  return (
    <div className="list-content-item">
      <div className="list-content-item--info">
        <div className="list-content-item--icon">
          <Picture className="icone" image={image} />
        </div>

        <div className="list-content-item--title">
          <p className="subtitle">{pre_title}</p>
          <p className="title">{title}</p>
        </div>

        <p className="list-content-item--description">{description}</p>
      </div>

      {link &&
        <LinkElement
          className="btn btn-border"
          link={getLink(link, params)}>Continuar</LinkElement>}
    </div>
  );
};

ListContentItem.propTypes = {
  item: PropTypes.object,
};

export default memo(ListContentItem);