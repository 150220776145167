import React from 'react';
import PropTypes from 'prop-types';

import './title-text-expanded.scss'

// title text expanded
const TitleTextExpanded = ({ value: { body_left, body_right } }) => {
  // render
  return (
    <div className="title-text-expanded">
      <div className="wrapper">
        <div className="title-text-expanded--content">
          <div className="title-text-expanded--text left" dangerouslySetInnerHTML={{ __html: body_left }} />
          <div className="title-text-expanded--text right" dangerouslySetInnerHTML={{ __html: body_right }} />
        </div>
      </div>
    </div>
  );
};

TitleTextExpanded.propTypes = {
  value: PropTypes.object.isRequired
};

export default TitleTextExpanded;