import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../../../helpers/useLinkRefactory';

import LinkElement from '../../../LinkElement';
import Picture from '../../../Picture';

import './list-highlights-item.scss';

// List high lights item
const ListHighlightsItem = ({ item, index, type }) => {
  // props
  const { description, image, title } = item;

  // link element
  const { linkElement } = useLinkRefactory();
  const link = linkElement(item);
  
  // type
  const getType = (type) => {
    switch (type) {
      case 'tipo2':
        return <p className="number"><span className="text">{index}</span></p>;
      default: 
        return <Picture image={image} />;
    }
  };

  // render
  return (
    <Fragment>
      {link && <LinkElement className="list-highlights--item" link={link} type={type}>
          <div className="icon">{getType(type)}</div>  

          <p className="name">{title}</p>
          <p className="description">{description}</p>
        </LinkElement>}
      
      {!link && <div className="list-highlights--item" data-type={type}>
        <div className="icon">{getType(type)}</div>  

        <p className="name">{title}</p>
        <p className="description">{description}</p>
      </div>}
    </Fragment>
  );
};

ListHighlightsItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  type: PropTypes.string
}

export default ListHighlightsItem;