import React, { memo } from 'react';
import PropTypes from 'prop-types';

import TabSubMenuContent from './TabSubMenuContent';

import './tab-submenu.scss';

// tab sub menu
const TabSubMenu = ({ location }) => {
  // check active
  const createTabSubMenu = () => {
    const { pathname } = location;
    const routeLenght = pathname.substring(1).split('/');

    return routeLenght.length > 1 ? true : false;
  };

  // render
  return (
    <div className="tab-submenu">
      {createTabSubMenu() && <TabSubMenuContent />}
    </div>
  );
};

TabSubMenu.propTypes = {
  location: PropTypes.object.isRequired,
}

export default memo(TabSubMenu);