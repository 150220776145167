import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import clamp from 'lodash-es/clamp';
import { useSprings, animated as a } from 'react-spring';
import { useDrag } from 'react-use-gesture';

// Webdoor content
const Slider = ({ children, className, current, items, setCurrent, width }) => {
  // props set
  const [ props, set ] = useSprings(items.length, i => ({
    x: i * width,
    scale: 1,
    display: 'block'
  }));

  // drag
  const drag = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (down && distance > width / 2)
      cancel((setCurrent(clamp(current + (xDir > 0 ? -1 : 1), 0, items.length - 1))));
    
    set(i => {
      if (i < current - 1 || i > current + 1) return { display: 'none' };

      const x = (i - current) * width + (down ? mx : 0);
      const scale = down ? 1 - distance / width / 2 : 1;
      return { x, scale, display: 'block' };
    });
  });

  // use effect
  useEffect(() => {
    const init = () => {
      set(i => {
        if (i < current - 1 || i > current + 1) {
          return { display: 'none' };
        }
  
        const x = (i - current) * width;
        const scale = 1;
  
        return { x, scale, display: 'block' };
      });
    };

    init();
  }, [ current, set, width ]);

  // render
  return (
    <a.div className={`${className}--list`} style={{ width: width }}>
      {props.map(({ x, display }, i) => (
        <a.div
          {...drag()}
          className={`${className}--item`}
          key={i}
          style={{ display, transform: x.interpolate(x => `translate3d(${x}px,0,0)`) }}>
          {children[i]}
        </a.div>))}
    </a.div>
  )
};

Slider.propTypes = {
  current: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default Slider;
