import React, { useCallback, useContext, memo } from 'react';
import PropTypes from 'prop-types';

import { withRouter, Switch, Route } from 'react-router-dom';
import { useSpring, config } from 'react-spring';

import { PageContext } from '../../providers/PageProvider';
import useNavigation from '../../helpers/useNavigation';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Footer from '../Footer';
import Header from '../Header';
import Page from '../Page';

// Content
const Content = ({ location, history, routes, route, setRoute }) => {
  // page context
  const pageContext = useContext(PageContext);
  const { page, loadPage } = pageContext;

  // get route
  const { getRoute } = useNavigation();

  // y position scroll top
  const [, setY ] = useSpring(() => ({
    immediate: false,
    config: config.slow,
    y: 0,
    onFrame: props => {
      window.scroll(0, props.y);
    }
  }));

  // set current route
  const setCurrentRoute = useCallback((location, routes) => {
    if (!Array.isArray(routes)) return false;

    const url = location.pathname.substring(1).split('/');

    if (url) {
      const route = getRoute(url[0], url[1], url[2], routes);

      if (route instanceof Object) {
        setY({ y: 0 });
        setRoute(route, routes);

        if (route.retranca === true && route.submenu.length > 0) {
          history.push(`${route.submenu[0].meta.slug}`);
        }

        loadPage(route);
      }
    }
  }, [ getRoute, setRoute, loadPage, setY, history ]);

  // use deep compare
  useDeepCompareEffect(() => {
    setCurrentRoute(location, routes);
  }, [ location, routes ]);

  // render
  return (
    <div className="main">
      <Header location={location} routes={routes} route={route} />
    
      <Switch>
        {routes && routes.map((route, index) => <Route
          exact
          key={index}
          path={`/${route.meta.slug}`}
          render={() => <Page location={location} page={page} />} />)}
      </Switch>

      <Footer />
    </div>
  );
};

Content.propTypes = { 
  location: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
  setRoute: PropTypes.func
};

export default memo(withRouter(Content));