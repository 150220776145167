import React from 'react';
import PropTypes from 'prop-types';

import CarrouselTimeLineSlider from './CarrouselTimeLineContent';

import './carrousel-timeline.scss';

// carrousel time line
const CarrouselTimeLine = ({ value: { listitems, title }}) => {
  // render
  return (
    <div className="carrousel-timeline">
      <div className="wrapper">
        <div className="carrousel-timeline--title">
          <h1 className="title-main">{title}</h1>
        </div>

        <CarrouselTimeLineSlider items={listitems} />
      </div>
    </div>
  );
};

CarrouselTimeLine.propTypes = {
  value: PropTypes.object.isRequired,
};

export default CarrouselTimeLine;