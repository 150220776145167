import BankAsaServiceHighlight from '../../components/BankAsaServiceHighlight';
import BoxAlert from '../../components/BoxAlert';
import CarrouselTimeLine from '../../components/CarrouselTimeLine';
import FormContact from '../../components/Form/Forms/FormContact';
import FormDenounce from '../../components/Form/Forms/FormDenounce';
import FormCreditJuridic from '../../components/Form/Forms/FormCreditJuridic';
import FormInvestiment from '../../components/Form/Forms/FormInvestiment';
import ImageText from '../../components/ImageText';
import ListBenefits from '../../components/List/ListBenefits';
import ListCarrouselPhoto from '../../components/List/ListCarrouselPhoto';
import ListCarrouselVideo from '../../components/List/ListCarrouselVideo';
import ListContent from '../../components/List/ListContent';
import ListStatic from '../../components/List/ListStatic';
import ListHighlights from '../../components/List/ListHighlights';
import ListProducts from '../../components/List/ListProducts';
import MissionVisionValues from '../../components/MissionVisionValues';
import ReadMore from '../../components/ReadMore';
import Reports from '../../components/Reports';
import Quote from '../../components/Quote';
import Profile from '../../components/Profile';
import ProductCreditsJuridic from '../../components/ProductCreditsJuridic';
import ProductCreditsFisic from '../../components/ProductCreditsFisic';
import ProductInvestment from '../../components/ProductInvestment';
import PhotoSpecial from '../../components/PhotoSpecial';
import TextHighLight from '../../components/TextHighLight';
import TextSliderHighLight from '../../components/TextSliderHighLight';
import TitleTextExpanded from '../../components/TitleTextExpanded';
import TitleText from '../../components/TitleText';
import Webdoor from '../../components/Webdoor';

// Type components
export const TYPE_COMPONENTS = [
  {
    type: 'component_boxalert',
    componentElement: BoxAlert
  }, {
    type: 'component_profile',
    componentElement: Profile
  }, {
    type: 'component_listcarrousel_video',
    componentElement: ListCarrouselVideo
  }, {
    type: 'component_listcarrousel_cardalbum',
    componentElement: TextSliderHighLight
  },{
    type: 'component_benefits',
    componentElement: ListBenefits
  }, {
    type: 'component_title_text_expanded',
    componentElement: TitleTextExpanded
  }, {
    type: 'component_bankasaservice_highlight',
    componentElement: BankAsaServiceHighlight
  }, {
    type: 'component_listcarrousel_photoalbum',
    componentElement: ListCarrouselPhoto
  }, {
    type: 'component_image_text',
    componentElement: ImageText
  }, {
    type: 'component_texthighlight',
    componentElement: TextHighLight
  }, {
    type: 'component_cardhighlight_content',
    componentElement: ListContent
  }, {
    type: 'component_formulario_denuncia',
    componentElement: FormDenounce
  }, {
    type: 'component_formulario_fale_conosco',
    componentElement: FormContact
  }, {
    type: 'component_formulario_investimento',
    componentElement: FormInvestiment
  }, {
    type: 'component_formulario_credito_juridica',
    componentElement: FormCreditJuridic
  }, {
    type: 'component_produto_investimentos',
    componentElement: ProductInvestment
  }, {
    type: 'component_produto_creditos_juridica',
    componentElement: ProductCreditsJuridic
  }, {
    type: 'component_produto_creditos_fisica',
    componentElement: ProductCreditsFisic
  }, {
    type: 'component_listcarrousel_timeline',
    componentElement: CarrouselTimeLine
  }, {
    type: 'component_quote',
    componentElement: Quote
  },{
    type: 'component_missionvisionvalues',
    componentElement: MissionVisionValues
  }, {
    type: 'component_reports',
    componentElement: Reports
  }, {
    type: 'component_title_text',
    componentElement: TitleText
  }, {
    type: 'component_webdoor',
    componentElement: Webdoor,
  }, {
    type: 'component_highlight_editor',
    componentElement: ListProducts
  }, {
    type: 'component_differentials',
    componentElement: ListStatic
  }, {
    type: 'component_cta_contact',
    componentElement: ReadMore
  }, {
    type: 'component_card_especial',
    componentElement: PhotoSpecial
  }, {
    type: 'component_listcarrousel_highlight',
    componentElement: ListHighlights
  }
];