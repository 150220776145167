import React from 'react';
import PropTypes from 'prop-types';

import ReportsItem from '../ReportsItem';

import './reports-lists.scss';

// reports lists
const ReportsList = ({ items }) => {
  // render
  return (
    <ul className="reports--list">
      {items && items.map((item, index) =>
        <li className="reports--list--item" key={index}>
          <ReportsItem {...item} />
        </li>)}
    </ul>
  );
};

ReportsList.propTypes = {
  items: PropTypes.array.isRequired,
};

export default ReportsList;