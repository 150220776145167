import React, { memo, useCallback, useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import ProductCreditsFisicItemContent from './ProductCreditsFisicItemContent';
import ProductCreditsFisicItemSelect from './ProductCreditsFisicItemSelect';
import ProductCreditsFisicItemTitle from './ProductCreditsFisicItemTitle';

import './product-credits-fisic-item.scss';

// product credits fisic item
const ProductCreditsFisicItem = ({ id, descricao, icone, nome }) => {
  // state
  const [ item, setItem ] = useState({});
  const [ active, setActive ] = useState(false);

  // on change
  const onChange = useCallback((item) => {
    setItem(item);
    
    if (item instanceof Object && item.value) {
      return setActive(true);
    }
    
    setActive(false);
  }, [ setItem ]);

  // render
  return (
    <Fragment>
      <div className="product-credits-fisic-item" data-active={active}>
        {item &&
          <div className="product-credits-fisic-item--info">
            <ProductCreditsFisicItemTitle icon={icone} name={nome} />
            <ProductCreditsFisicItemSelect
                id={id}
                description={descricao}
                item={item}
                onChange={onChange} />
          </div>}
        </div>

      {item &&
        <ProductCreditsFisicItemContent
          active={active}
          setActive={setActive}
          item={item} id={id} setItem={setItem} />}
    </Fragment>
  );
};

ProductCreditsFisicItem.propTypes = {
  id: PropTypes.number,
  descricao: PropTypes.any,
  icone: PropTypes.object,
  nome: PropTypes.string
};

export default memo(ProductCreditsFisicItem);