import React, { Children } from 'react';
import PropTypes from 'prop-types';

import './categories-tab-content.scss';

// categories tab content
const CategoriesTabContent = ({ children, current }) => {
  // render
  return (
    <ul className="categories-tab-content">
      {children && Children.map(children, (child, index) =>
        <li className="categories-tab-content--item" data-current={current === index} key={index}>{child}</li>)}
    </ul>
  );
};

CategoriesTabContent.propTypes = {
  children: PropTypes.array.isRequired,
  current: PropTypes.number,
}

export default CategoriesTabContent;