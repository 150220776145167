import React from 'react';
import PropTypes from 'prop-types';

import './list-benefits.scss';

// list benefits
const ListBenefits = ({ value }) => {
  // const
  const { listitems, title } = value[0];

  // render
  return (
    <div className="list-benefits">
      <div className="wrapper">
        <div className="list-benefits--content">
          <p className="title">{title}</p>

          <ul className="list-benefits--list">
            {listitems && listitems.map(({ benefits }, index) =>
              <li className="list-benefits--item" key={index}>
                <span className="icon icon-check"></span>
                <p className="text">{benefits}</p>
              </li>)}
          </ul>
        </div>
      </div>
    </div>
  );
};

ListBenefits.propTypes = {
  value: PropTypes.array.isRequired,
};

export default ListBenefits;