import React from 'react';
import PropTypes from 'prop-types';

import CategoriesTabContentItem from '../CategoriesTabContentItem';

import './categories-tab-categories-list.scss';

// categories tab categories list
const CategoriesTabContentList = ({ current, children, categories, items, setCurrent }) => {
  // on select
  const onSelect = item => {
    if (!Array.isArray(items)) return false;

    const index = items.indexOf(item);

    if (index !== -1) {
      setCurrent(index);
    }
  };

  // filter children
  const filterChildren = (id, items) => {
    const tabItems = [];

    for (let i in items) {
      const item = items[i];

      if (item instanceof Object) {
        if (item.categoria.id === id) {
          item.index = parseInt(i);
          tabItems.push({ element: children[i], item: item });
        }
      }
    }

    return tabItems;
  };

  // render
  return (
    <ul className="categories-tab-categories-list">
      {categories && categories.map((category, index) =>
        <li className="categories-tab-categories-list--item" key={index}>
          <CategoriesTabContentItem
            current={current}
            item={category}
            onSelect={onSelect}
            items={filterChildren(category.id, items)} />
        </li>)}
    </ul>
  );
};

CategoriesTabContentList.propTypes = {
  current: PropTypes.number.isRequired,
  categories: PropTypes.array,
  items: PropTypes.array,
  setCurrent: PropTypes.func.isRequired
}

export default CategoriesTabContentList;