import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import HeaderMenu from './HeaderMenu';

import './header.scss';

// Header
const Header = ({ location, routes, route }) => {
  // state
  const [ open, setOpen ] = useState(false);

  // use effect
  useEffect(() => {
    setOpen(false);
  }, [ route ]);

  // render
  return (
    <header className="header" data-open={open}>
      <div className="wrapper">
        <div className="header--logo">
          <button className="open" onClick={() => setOpen(!open)}>
            <span className={`icon icon-${open ? 'close' : 'menu'}`}></span>
          </button>

          <a className="logo" href="/">
            <img src={`${process.env.PUBLIC_URL}images/logo.svg`} alt="Lecca" />
          </a>
        </div>
      
        <HeaderMenu location={location} routes={routes} route={route} open={open} setOpen={setOpen} />

        <div className="header--user">
          <Link className="link link-default" to="/carreiras">Carreiras</Link>
          <Link className="btn btn-default" to="/area-do-cliente">Área do cliente</Link>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.object,
  routes: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
};

export default memo(Header);