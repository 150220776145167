import React, { memo, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import axios from 'axios';
import { Formik, Form } from 'formik';

import useForm from '../../../helpers/useForm';

import Input from '../Input';
import Select from '../../Select';
import FormsInfo from './FormsInfo';

// form credit juridic
const FormContact = ({ value: { title, body }}) => {
  // state
  const [ subjects, setSubject ] = useState([]);
  const [ error, setError ] = useState(false);
  const [ selectSubject, setSelectSubject ] = useState({ value: '', label: '' });

  // params
  const location = useLocation();

  // use form
  const { checkValue } = useForm();

  // initial
  const initialValues = {
    nome: '',
    email: '',
    ddd: '',
    telefone: '',
    assunto: '',
    mensagem: '',
    aceita_receber_comunicados: 'false'
  };

  // select subject
  const onSetSubject = (value) => {
    setSelectSubject(value);
  };

  // options
  useEffect(() => {
    const api = async () => {
      const subject = await axios({
        method: 'GET',
        url: '/api/v2/formularios/fale-conosco/assuntos',

      }).then(({ data }) => {
        if (data instanceof Object) {
          return data.map(({ id, nome }) => ({ value: id, label: nome }));
        }

        return [];
      });

      if (subject) {
        const params = new URLSearchParams(location.search);
        const select_option = parseInt(params.get('select_option'));

        if (select_option) {
          const select_search = subject.filter((item) => item.value === select_option);

          if (select_search.length > 0) {
            setSelectSubject(select_search[0]);
          }
        } else {
          if (subject.length > 0) {
            const select_default = subject.filter((item) => item.ordem === 0);

            if (select_default.length > 0) {
              setSelectSubject(select_default[0]);
            } else {
              setSelectSubject(subject[0]);
            }
          }
        }
        
        setSubject(subject);
      }
    };

    api();
  }, [ location ]);

  // render
  return (
    <div className="form" data-type="contact" data-error={error}>
      <div className="wrapper">
        <FormsInfo body={body} title={title} />

        <div className="form--inputs">
          <Formik
            //init
            initialValues={initialValues}
            initialStatus={{ success: false }}

            // validate
            validate={values => {
              const errors = {};
              const { nome, email, ddd, telefone } = values;

              if (nome !== '' && !checkValue(nome, 'text'))
                errors.nome = true;

              if (email !== '' && !checkValue(email, 'email'))
                errors.email = true;

              if (ddd !== '' && !checkValue(ddd, 'number'))
                errors.ddd = true;

              if (telefone !== '' && !checkValue(telefone, 'phone'))
                errors.telefone = true;
              
              if (selectSubject.value === '')
                errors.assunto = true;

              if (values.mensagem === '')
                errors.mensagem = true;

              setError(Object.keys(errors).length > 0);
              
              return errors;
            }}

            // on submit
            onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
              setStatus({ success: false });

              const params = values;
              params.assunto = selectSubject.value;
              params.telefone = params.ddd.toString() + params.telefone.toString();

              axios.post('/api/v2/formularios/fale-conosco', {
                ...params
              }).then(e => {
                resetForm(initialValues);
                setSelectSubject({});
                setStatus({ success: true });
                return e;
              }).catch(e => {});

              setSubmitting(false);
            }}>

            {({
              errors,
              status,
              isSubmitting,
            }) => (
              <Form autoComplete="off">
                <div className="input-group">
                  <Input label="Nome" name="nome" type="text" error={errors.nome} theme="white" required={true} />
                </div>

                <div className="input-group">
                  <Input label="Email" name="email" type="email" error={errors.email} theme="white" required={true} />
                </div>

                <div className="input-group join" data-error={errors.ddd || errors.telefone} data-theme="white">
                  <label className="label">telefone</label>
                  <Input label="" name="ddd" type="ddd" error={errors.ddd} theme="white" required={true} />
                  <Input label="" name="telefone" type="phone" error={errors.telefone} theme="white" required={true} />
                </div>

                <div className="input-group">
                  <label className="input-control" data-type="select" data-error={errors.assunto} data-theme="white">
                    <label className="label">Assunto</label>

                    <Select
                      defaultText="Selecione o assunto"
                      options={subjects}
                      handleChange={onSetSubject}
                      selectedOption={selectSubject} />

                    <input name="assunto" type="hidden" required={true} />
                  </label>
                </div>

                <div className="input-group">
                  <Input
                    label="sua mensagem"
                    name="mensagem"
                    type="textarea"
                    info="600 caracteres restantes"
                    error={errors.mensagem}
                    theme="white"
                    required={true} />
                </div>
                  
                {!status.success &&
                  <button
                    className="btn btn-default"
                    type="submit"
                    disabled={isSubmitting}>enviar</button>}
                
                {status.success === true &&
                  <div className="input-message">
                    <p className="message">
                      <span className="title">Obrigado por entrar em contato conosco.</span>
                      <span className="description">Sua mensagem será direcionada para a equipe responsável e logo entraremos em contato com você.</span>
                    </p>
                  </div>}

                <div className="input-group">
                  <Input
                    label="Concordo receber futuros comunicados da Lecca"
                    name="aceita_receber_comunicados"
                    type="check"
                    theme="white" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default memo(FormContact);