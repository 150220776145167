import React from 'react';
import PropTypes from 'prop-types';

import './product-credits-fisic-item-content-gallery-item.scss';

// product credits fisic content gallery item
const ProductCreditsFisicContentGalleryItem = ({ city: { label }, endereco, nome, url, index, setCurrent, width }) => {
  // render
  return (
    <div className="product-credits-fisic-item-content-gallery-item"
      onClick={() => setCurrent(index)}
      style={{ width }}>
      <div className="info">
        {nome && <p className="name">{nome}</p>}
        {endereco && <div className="address">{endereco}</div>}
        {url && <a className="link" href={url} target="_blank" rel="noopener noreferrer">{url}</a>}
      </div>

      <p className="city">
        <span className="icon icon-piker"></span>
        <span className="label">{label}</span>
      </p>
    </div>
  );
};

ProductCreditsFisicContentGalleryItem.propTypes = {
  city: PropTypes.object,
  endereco: PropTypes.string,
  nome: PropTypes.string,
  url: PropTypes.string,
  setCurrent: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
}

export default ProductCreditsFisicContentGalleryItem;