// use image
function useImage () {
  // large
  const defaultImage = image => {
    if (image instanceof Object) {
      const { original, large: { alt, src }, title } = image;

      if ((/\.(gif)$/i).test(title)) {
        return { alt: original.alt, src: `${process.env.REACT_APP_BASE_API_LECCA}${original.src}` };
      }

      return { alt, src: `${process.env.REACT_APP_BASE_API_LECCA}${src}` };
    }

    return {};
  };

  // icone
  const iconImage = icon => {
    if (icon instanceof Object) {
      const { thumbnail: { src }, alt } = icon;

      return { alt, src: `${process.env.REACT_APP_BASE_API_LECCA}${src}` };
    }

    return false;
  }

  return {
    defaultImage,
    iconImage,
  }
}

export default useImage;