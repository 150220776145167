import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { Formik, Form } from 'formik';

import useForm from '../../../helpers/useForm';

import Input from '../Input';
import Select from '../../Select';
import FormsInfo from './FormsInfo';

// form credit juridic
const FormCreditJuridic = ({ value: { title, body }}) => {
  // state
  const [ products, setProducts ] = useState([]);
  const [ error, setError ] = useState(false);
  const [ selectProduct, setSelectProduct ] = useState({ value: '', label: '' });

  // use form
  const { checkValue, checkNumber } = useForm();

  // initial
  const initialValues = {
    nome: '',
    email: '',
    cnpj: '',
    faturamento: 0,
    informacoes_adicionais: '',
    aceita_receber_comunicados: 'false'
  };

  // selec product
  const onSetProduct = (value) => {
    setSelectProduct(value);
  };

  // options
  useEffect(() => {
    const api = async () => {
      const products = await axios({
        method: 'GET',
        url: '/api/v2/formularios/credito-juridica/produtos',

      }).then(({ data }) => {
        if (data instanceof Object) {
          return data.map(({ id, nome }) => ({ value: id, label: nome }));
        }

        return [];
      });

      if (products) {
        setProducts(products);
      }
    };

    api();
  }, []);

  // render
  return (
    <div className="form" data-error={error}>
      <div className="wrapper">
        <FormsInfo body={body} title={title} />

        <div className="form--inputs">
          <Formik
            //init
            initialValues={initialValues}
            initialStatus={{ success: false }}

            // validate
            validate={values => {
              const errors = {};

              if (values.nome !== '' && !checkValue(values.nome, 'text'))
                errors.nome = true;

              if (values.email !== '' && !checkValue(values.email, 'email'))
                errors.email = true;

              if (values.faturamento !== 0 && checkNumber(values.faturamento) <= 0)
                errors.faturamento = true;

              if (values.cnpj !== '' && !checkValue(values.cnpj, 'cnpj'))
                errors.cnpj = true;
              
              if (selectProduct.value === '')
                errors.produto = true;

              setError(Object.keys(errors).length > 0);
              
              return errors;
            }}

            // on submit
            onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
              setStatus({ success: false });

              const params = values;
              params.produto = selectProduct.value;
              params.faturamento = Number(params.faturamento.toString().replace(/[^0-9\\.]+/g, ''));

              axios.post('/api/v2/formularios/credito-juridica', {
                ...params
              }).then(e => {
                resetForm(initialValues);
                setSelectProduct({});
                setStatus({ success: true });
                return e;
              });

              setSubmitting(false);
            }}>

            {({
              errors,
              status,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form autoComplete="off">
                  <div className="input-group two">
                    <Input label="Nome" name="nome" type="text" error={errors.nome} required={true} />
                    <Input label="Email" name="email" type="email" error={errors.email} required={true}  />
                  </div>

                  <div className="input-group two">
                    <label className="input-control" data-type="select" data-error={errors.produto}>
                      <label className="label">produto de interesse</label>

                      <Select
                        defaultText="Selecione um produto"
                        options={products}
                        handleChange={onSetProduct}
                        selectedOption={selectProduct}
                        type="2" />

                      <input name="produto" type="hidden" required={true} />
                    </label>

                    <Input label="cnpj" name="cnpj" type="cnpj" error={errors.cnpj} required={true} />
                  </div>

                  <div className="input-group two">
                    <Input
                      label="faturamento"
                      name="faturamento"
                      type="money"
                      maxLength={9999999999999}
                      setFieldValue={setFieldValue}
                      error={errors.faturamento}
                      info="Últimos 12 meses"
                      required={true} />
                  </div>

                  <div className="input-group">
                    <Input
                      label="Informações adicionais"
                      name="informacoes_adicionais"
                      type="textarea"
                      info="600 caracteres restantes"
                      error={errors.informacoes_adicionais}
                      required={true} />
                  </div>
                  
                  {!status.success &&
                    <button
                      className="btn btn-border w"
                      type="submit"
                      disabled={isSubmitting}>enviar</button>}
                  
                  {status.success === true &&
                    <div className="input-message">
                      <p className="message">
                        <span className="title">Obrigado por entrar em contato conosco.</span>
                        <span className="description">Sua mensagem será direcionada para a equipe responsável e logo entraremos em contato com você.</span>
                      </p>
                    </div>}

                  <div className="input-group">
                    <Input
                      label="Concordo receber futuros comunicados da Lecca"
                      name="aceita_receber_comunicados"
                      type="check"
                      required={true} />
                  </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FormCreditJuridic;