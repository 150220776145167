import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { FooterContext } from '../../../providers/FooterProvider';
import FooterAddress from '../FooterAddress';

import './footer-top.scss';

// Footer Top
const FooterTop = ({ partners }) => {
  // context
  const footerContext = useContext(FooterContext);
  const { address } = footerContext;

  // render
  return (
    <div className="footer--top">
      <div className="wrapper">
        <div className="footer--top--title">
          <img src={process.env.PUBLIC_URL + '/images/logo-footer.png'} alt={'logo'} />

          <div className="footer--credits mobile">
            <a className="link" href="/">Copyright © LECCA 2020</a>
          </div>
        </div>

        <FooterAddress items={address} />

        <div className="footer--top--partners">
          {partners && partners.map(({ title, img: { src } }, index) =>
            <div className="footer--top--partners--item" key={index}>
              <p className="title">{title}</p>
              <img className="image" src={process.env.PUBLIC_URL + src} alt={title} />
            </div>)}
        </div>
      </div>
    </div>
  );
};

FooterTop.propTypes = {
  any: PropTypes.any,
}

export default FooterTop;