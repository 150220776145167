import React, { memo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import ProductCreditsJuridicContent from './ProductCreditsJuridicContent';

import './product-credits-juridic.scss';

// product credits juridic
const ProductCreditsJuridic = ({ value: { title } }) => {
  // state
  const [ state, setState ] = useState({
    categories: [],
    items: [],
  });

  // get categories
  const getCategories = useCallback((data) => {
    if (Array.isArray(data) === false) return false;
    const categories = [];

    for (let i = 0; i < data.length; i++) {
      const { categoria } = data[i];
      const check = categories.filter(item => categoria.id === item.id);

      if (check.length === 0) {
        categories.push(categoria);
      }
    }

    return categories;
  }, []);

  // usee effect
  useEffect(() => {
    const api = (async () => {
      const data = await axios.get('/api/v2/produtos/creditos-juridica')
        .then(({ data }) => {
          const categories = getCategories(data);
          const items = data;

          return {
            categories,
            items
          };
        });
      
      setState(data);
    });

    api();
  }, [ getCategories ]);

  // render
  return (
    <div className="product-credits-juridic">
      <div className="product-credits-juridic--title">
        <p className="text">{title}</p>
      </div>

      <ProductCreditsJuridicContent {...state} />
    </div>
  );
};

ProductCreditsJuridic.propTypes = {
  value: PropTypes.object.isRequired,
}

export default memo(ProductCreditsJuridic);