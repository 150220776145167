import React from 'react';
import PropTypes from 'prop-types';

import ReportsSubItem from '../ReportsSubItem';

import './reports-item.scss';

// reports item
const ReportsItem = ({ categoria, listitems }) => {
  // render
  return (
    <div className="reports--item">
      {categoria && <p className="category">{categoria}</p>}

      <ul className="reports--item--list">
        {listitems && listitems.map((item, index) =>
          <li className="reports--item--subitem" key={index}>
            <ReportsSubItem {...item} />
          </li>)}
      </ul>
    </div>
  );
};

ReportsItem.propTypes = {
  categoria: PropTypes.string,
  listitems: PropTypes.array.isRequired,
};

export default ReportsItem;