import React, { Fragment, useEffect, useRef } from 'react';
import { useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import clamp from 'lodash-es/clamp';

import CarrouselTimeLineControls from '../CarrouselTimeLineControls';
import CarrouselTimeLineItem from '../CarrouselTimeLineItem';
import CarrouselTimelineTime from '../CarrouselTimeLineTime';
import SliderBase from '../../Slider/Base';

import './carrousel-timeline-content.scss';

// carrousel tiem line content
const CarrouselTimeLineContent = ({ items, current, setCurrent, onPrevNext }) => {
  // element
  const element = useRef(null);

  // props set
  const [ props, set ] = useSprings(items.length, i => ({
    x: i * window.innerWidth,
    scale: 1,
    display: 'block'
  }));

  // drag
  const drag = useDrag(({ down, movement: [mx], direction: [xDir], distance, cancel }) => {
    if (down && distance > 200) {
      cancel((setCurrent(clamp(current + (xDir > 0 ? -1 : 1), 0, items.length - 1))));
    }
    
    set(i => {
      // width 
      const { width } = element.current || element.current instanceof Object ? element.current.getBoundingClientRect() : 0;

      if (i < current - 1) return { display: 'none' };

      const x = (i - current) * width + (down ? mx : 0);
      return { x, display: 'block' };
    });
  });

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      const init = () => {
        set(i => {
          const { width } = element.current || element.current instanceof Object ? element.current.getBoundingClientRect() : 0;

          if (i < current - 1) return { display: 'none' };
    
          const x = (i - current) * width;
          return { x, display: 'block' };
        });
      };

      init();
    }
  }, [ current, set ]);

  // render
  return (
    <Fragment>
      <div className="carrousel-timeline--content" ref={element}>
        <CarrouselTimeLineControls current={current} total={items.length} onPrevNext={onPrevNext} />

        <div className="carrousel-timeline--content--list">
          {props.map(({ x, display }, i) => 
            <CarrouselTimeLineItem drag={drag} display={display} item={items[i]} x={x} active={i === current} key={i} />)}
        </div>
      </div>

      <CarrouselTimelineTime current={current} items={items} />
    </Fragment>
  );
};

export default SliderBase(CarrouselTimeLineContent);