import React, { useContext } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import { NavContext } from '../../providers/NavProvider';

import AlertButton from '../../components/AlertButton';
import Content from '../Content';

import './main.scss';

// Main
const Main = () => {
  // nav context
  const navContext = useContext(NavContext);

  // render
  return (
    <ParallaxProvider>
      <Content {...navContext} />

      <AlertButton />
    </ParallaxProvider>
  );
};

export default Main;