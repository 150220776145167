import React from 'react';
import PropTypes from 'prop-types';

import './select-title.scss';

// select title
const SelectTitle = ({ active, defaultText, onToggle, selectedOption: { label, value } }) => {
  // render
  return (
    <div className="select-title" onClick={() => onToggle(!active)} data-active={active}>
      <p className="select-title--content">
        <span className="text" data-empty={value ? true : false}>{ label ? label : defaultText }</span>
        <span className={`icon ${active ? 'icon-arrow-top' : 'icon-arrow-down'}`}></span>
      </p>
    </div>
  );
};

SelectTitle.propTypes = {
  active: PropTypes.bool.isRequired,
  defaultText: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
}

export default SelectTitle;