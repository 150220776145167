import React from 'react';
import PropTypes from 'prop-types';

import { Parallax } from 'react-scroll-parallax';

import ListStaticList from './ListStaticList';
import Picture from '../../Picture';

import './list-static.scss';

// List static
const ListStatic = ({ value }) => {
  // remove
  const info = {
    title: 'Nossos diferenciais',
    description: 'Ao operar conosco, você conta com algumas vantagens e facilidades, que tornam toda a sua experiência de investimento mais satisfatória. Veja algumas delas:'
  };

  // props
  const { image_background, listitems, subtype } = value[0];

  // get title
  const getTitle = ({ title, description }) => {
    return <div className="list-static--title">
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
    </div>;
  };

  // render
  return (
    <div className="list-static" data-type={subtype}>
      <div className="list-static--background">
        <Parallax y={[-20, 0]}>
          <Picture image={image_background} />
        </Parallax>
      </div>

      <div className="wrapper">
        {subtype === 2 && info && getTitle(info)}

        <ListStaticList listitems={listitems} />
      </div>
    </div>
  );
};

ListStatic.propTypes = {
  value: PropTypes.array.isRequired,
};

export default ListStatic;