import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import ProductCreditsFisicContentGallery from '../../ProductCreditsFisicContent/ProductCreditsFisicContentGallery';

import './product-credits-fisic-item-content.scss';

// product credits fisic item content
const ProductCreditsFisicItemContent = ({ active, setActive, id, item, setItem }) => {
  // state
  const [ items, setItems ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  // use effect
  useEffect(() => {
    if (item.id) {
      const api = () => {
        return axios.get(`/api/v2/produtos/correspondentes-fisica?credito=${id}&uf=${item.id}`)
          .then(({ data }) => {
            setItems(data)

            if (data instanceof Object) setLoading(true);
          }).catch(() => setLoading(false));
        };
      
      api();
    } else {
      setItems([]);
      setActive(false);
      setLoading(false);
    }
  }, [ id, item, setLoading, setActive ]);

  // render
  return (
    <div className="product-credits-fisic-item--content" data-active={active} data-loading={loading}>
      <ProductCreditsFisicContentGallery items={items} city={item} setItem={setItem} />
    </div>
  );
};

ProductCreditsFisicItemContent.propTypes = {
  item: PropTypes.object,
  setItem:  PropTypes.func.isRequired,
  active: PropTypes.bool,
  id: PropTypes.number
};

export default ProductCreditsFisicItemContent;