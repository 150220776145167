import { useCallback } from "react";

// use Navigation
function useNavigation () {
  // split url
  const splitUrl = useCallback(route => {
    if (route.meta.slug === 'home') return ['/'];

    if (route.meta instanceof Object) {
      const url = route.meta.html_url.substring(0, route.meta.html_url.length - 1);
      const arrayUrl = url.replace(process.env.REACT_APP_REPLACE, '').split('/');

      return arrayUrl.length > 0 ? arrayUrl : false;
    }

    return false;
  }, []);

  // get join route
  const getJoinRoute = useCallback((route, slug) => {
    if (slug === '/') return '/';

    if (Array.isArray(route)) {
      let url = '';

      for (let keyUrl in route) {
        if (route[keyUrl] !== '') {
          url += `${route[keyUrl]}/`;
        }
      }
      
      return url.substring(0, url.length - 1);
    }
  }, []);

  // order by
  const orderById = useCallback((a, b) => {
    if (a.id < b.id) { return -1; }
    if (a.id > b.id) { return 1; }
    return 0;
  }, []);

  // create submenu
  const createSubmenu = useCallback((route, routes, routeSlugs) => {
    if (Array.isArray(routeSlugs)) {
      const main = routes.filter(route => route.meta.slug === routeSlugs[0])[0];
      
      if (main instanceof Object) {
        if (main.submenu instanceof Object) {
          if (main.submenu.indexOf(route) === -1) main.submenu.push(route);
        } else {
          main.submenu = [];
        }

        main.submenu.sort(orderById);
      }
    }

    route.meta.slug = getJoinRoute(splitUrl(route), route.meta.slug);

    return route;
  }, [ getJoinRoute, orderById, splitUrl ]);

  // find route parent submenu
  const routeParentSubmenu = useCallback((route, routes) => {
    return routes.filter((parent) => {
      if (parent.submenu) {
        for (let k in parent.submenu) {
          const child = parent.submenu[k];

          if (child instanceof Object) {
            if (child === route) return parent;
          }
        }
      }

      return false;
    });
  }, []);

  // get parent
  const getParent = useCallback((route, routes) => {
    for (let key in routes) {
      const xRoute = routes[key];

      if (xRoute instanceof Object) {
        const value = route.meta.slug.split('/')[0];
        const item = xRoute.meta.slug.split('/')[0];

        if (value === item) {
          return xRoute;
        }
      }
    }
  }, []);

  // join routes
  const getRoutes = useCallback(routes => {
    if (!routes) return false;

    const items = [];

    for (let key in routes) {
      const route = routes[key];
      const routeSlugs = splitUrl(routes[key]);

      items.push(createSubmenu(route, routes, routeSlugs));
    }

    return items;
  }, [ createSubmenu, splitUrl ]);

  // get route
  const getRoute = useCallback((main, second, three, routes) => {
    if (!main) {
      return routes[0];
    } else if (second) {
      const slug = `${main}/${second}`;

      if (three) {
        const slugT = `${main}/${second}/${three}`;
        return routes.filter(route => route.meta.slug === slugT)[0];
      }

      return routes.filter(route => route.meta.slug === slug)[0];
    } else {
      return routes.filter(route => route.meta.slug === main)[0];
    }
  }, []);

  // get route query
  const getRouteQuery = async (url) => {
    const routes = await fetch(`${url}/api/v2/pages/?type=paginas.pagina&fields=navegacao`, {
      headers:{
        'Content-Type': 'application/json'
      },
    })
    .then(res => res.json())
    .then(res => {
      const { items } = res;

      if (items instanceof Object) {
        items.map((item) => item.meta.html_url = decodeURIComponent(item.meta.html_url));
      }
      
      return items;
    });

    return routes;
  };

  // get menu main
  const getMenuMain = useCallback(routes => {
    if (Array.isArray(routes)) {
      const items = [];
      const ignoreRoutes = [];

      for (let key in routes) {
        const item = routes[key];
  
        if (item instanceof Object && item.navegacao === 'primaria') {
          if (item.hasOwnProperty('submenu') && item.submenu.length > 0) {
            const submenu = [];

            for (let subKey in item.submenu) {
              const subItem = item.submenu[subKey];

              if (subItem.meta.slug.split('/').length <= 2) {
                if (subItem instanceof Object && subItem.id !== item.id) {
                  ignoreRoutes.push(subItem.id);
                  submenu.push(subItem);
                }
              }
            }

            item.submenu = submenu;
          }

          if (ignoreRoutes.indexOf(item.id) === -1) {
            if (item.meta.slug.split('/').length <= 2) {
              items.push(item);
            } 
          }
        }
      }
  
      return items;
    }

    return [];
  }, []);

  // render
  return {
    getRoutes,
    getRoute,
    getRouteQuery,
    getMenuMain,
    getParent,
    routeParentSubmenu
  };
}

export default useNavigation;