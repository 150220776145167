import { useCallback } from "react";

// use Link Refactory
function useLinkRefactory () {
  // remove /
  const removeSlash = useCallback(url => url.slice(-1) === '/' ? url.slice(0, url.length - 1) : url, []);

  // link element
  const linkElement = useCallback(element => {
    if (!element || !element instanceof Object) return false;
    
    if (element.hasOwnProperty('internal_link') && element.internal_link instanceof Object) {
      if (!!element.internal_link.hasOwnProperty('url')) {
        const { url, title } = element.internal_link;
        return { url: removeSlash(url), name: title, target: '' };
      }
    } else if (element.hasOwnProperty('external_link') && !!element.external_link) {
      const { external_link } = element;
      return { url: external_link, name: external_link, target: '_blank' };
    }

    return false;
  }, [ removeSlash ]);

  return { removeSlash, linkElement };
}

export default useLinkRefactory;