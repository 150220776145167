import React from 'react';

import FooterProvider from '../../providers/FooterProvider';

import FooterBottom from './FooterBottom';
import FooterTop from './FooterTop';

import './footer.scss';

// Footer
const Footer = () => {
  // list
  const list = [{
      href: '/contato/ouvidoria',
      name: 'Ouvidoria'
    },
  ];

  // partners
  const partners = [{
    title: 'associados à',
    img: {
      src: 'images/associados.png'
    }
  }, {
    title: 'fiscalizados por',
    img: {
      src: 'images/fiscalizados.png'
    }
  }]

  // render
  return (
    <FooterProvider>
      <footer className="footer">
        <FooterTop partners={partners} />
        <FooterBottom list={list} />
      </footer>
    </FooterProvider>
  );
};

export default Footer;