import React from 'react';
import PropTypes from 'prop-types';

import ListCarrouselPhotoContent from './ListCarrouselPhotoContent';

import './list-carrousel-photo.scss';

// carrousel time line
const ListCarrouselPhoto = ({ value: { listitems }}) => {
  // render
  return (
    <div className="list-carrousel-photo">
      <div className="wrapper">
        <ListCarrouselPhotoContent items={listitems} />
      </div>
    </div>
  );
};

ListCarrouselPhoto.propTypes = {
  value: PropTypes.object.isRequired,
};

export default ListCarrouselPhoto;