import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import clamp from 'lodash-es/clamp';
import { useDrag } from 'react-use-gesture';
import { useSpring, animated as a } from 'react-spring';

import ListHighlightsItem from '../ListHighlightsItem';

import './list-highlights-slider.scss';

// List High lights slider
const ListHighlightsSlider = ({ current, element, items, getProperties, setCurrent, type }) => {
  // props set
  const [ props, set ] = useSpring(() => ({ x: 0, width: 'auto' }));

  // drag
  const drag = useDrag(({ down, direction: [ xDir ], distance, cancel }) => {
    const { limit } = getProperties();

    if (down && distance > 50) {
      cancel((setCurrent(clamp(current + (xDir > 0 ? -1 : 1), 0, items.length - limit))));
    }
    
    animate();
  });

  // animate
  const animate = useCallback(() => {
    const parent = element.current;

    if (parent instanceof Object) {
      const item = parent.querySelector('.list-highlights--item');
      const itemProps = item.getBoundingClientRect();
      const percent = (current * itemProps.width);

      set({
        x: percent,
        width: Math.round(itemProps.width * items.length),
      });
    }
  }, [ current, element, set, items ]);

  // use effect
  useEffect(() => {
    if (element.current === null) return;

    animate();
  }, [ current, animate, element ]);

  // render
  return (
    <div className="list-highlights--slider" {...drag()}>
      <a.div
        className="list-highlights--slider--content"
        style={{
          transform: props.x.interpolate(x => `translateX(${x * -1}px)`),
          width: props.width.interpolate(width => `${width}px`),
        }}>
        {items && items.map((item, index) =>
          <ListHighlightsItem item={item} type={type} index={index + 1} key={index} />)}
      </a.div>
    </div>
  );
};

ListHighlightsSlider.propTypes ={
  current: PropTypes.number.isRequired,
  element: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  getProperties: PropTypes.func.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default ListHighlightsSlider;