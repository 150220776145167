import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './tab-submenu-list.scss';

// tab sub menu list
const TabSubMenuList = ({ items }) => {
  // render
  return (
    <ul className="tab-submenu--list">
      {items && items.map((item, index) => 
        <li className="tab-submenu--item" key={index} data-remove={item.meta.slug.split('/').length > 2}>
          <NavLink
            className="btn btn-default d"
            activeClassName="active" to={`/${item.meta.slug}`}
            exact>{item.title}</NavLink>
        </li>)}
    </ul>
  );
};

TabSubMenuList.propTypes = {
  items: PropTypes.array.isRequired,
}

export default TabSubMenuList;