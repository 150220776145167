import React, { createContext } from 'react';

import NavProvider from '../NavProvider';
import PageProvider from '../PageProvider';

// Main Context
const MainContext = createContext({
  active: true,
});

// Main Provider
const MainProvider = ({ children }) => {
  // render
  return (
    <NavProvider>
      <PageProvider>
        <MainContext.Provider value={{
          active: true,
        }}>
          {children}
        </MainContext.Provider>
      </PageProvider>
    </NavProvider>
  );
};

export { MainContext, MainProvider };
export default MainProvider;