import React from 'react';
import PropTypes from 'prop-types';

import './select-item.scss';

// select item
const SelectItem = ({ item, onSelectOption, selectedOption }) => {
  // props
  const { value, label } = item;

  // render
  return (
    <div className="select-item" onClick={() => onSelectOption(item)} data-active={selectedOption.value === value}>
      <p className="text">{label}</p>
    </div>
  );
};

SelectItem.propTypes = {
  item: PropTypes.object.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
};

export default SelectItem;