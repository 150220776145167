import React from 'react';
import PropTypes from 'prop-types';

import LinkElement from '../../LinkElement';

import './read-more-info.scss';

// read more info
const ReadMoreInfo = ({ description, link, title }) => {
  // render
  return (
    <div className="read-more--info">
      <p className="title">{title}</p>

      <div className="read-more--info--content">
        <p className="description">{description}</p>
        {link && <LinkElement className="btn btn-border" link={link}>{link.name}</LinkElement>}
      </div>
    </div>
  );
};

ReadMoreInfo.propTypes = {
  description: PropTypes.string,
  link: PropTypes.object,
  title: PropTypes.string.isRequired,
}

export default ReadMoreInfo;