import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';
import useDeepCompareEffect from 'use-deep-compare-effect';

import useLocalStorage from '../../helpers/useLocalStorage';

import ProductCreditsFisicContent from './ProductCreditsFisicContent';

import './product-credits-fisic.scss';

// product credits fisic
const ProductCreditsFisic = ({ value: { body } }) => {
  // use helper
  const { checkHours, localStorageItem, setValueFunction, setValue } = useLocalStorage('lecca_products', { items: [], date: Date.now() });
  // items
  const [ items, setItems ] = useState([]);

  // set all items
  const setLocalItems = useCallback((items) => {
    if (items instanceof Object === false) return false;

    setItems(items);
  }, [ setItems ]);
  
  // get items
  const setRequestItems = useCallback(async (setValue, setValueFunction) => {
    return await axios.get(`/api/v2/produtos/creditos-fisica`).then(({ data }) =>
        setValue('lecca_products', { items: data, date: Date.now() }, setValueFunction).then(() => setLocalItems(data)));
  }, [ setLocalItems ]);

  // load
  const load = useCallback((setValue, setValueFunction) => {
    const { items, date } = localStorageItem;;
    
    if (items && items.length > 0 && checkHours(date) < process.env.REACT_APP_LOCALSTORAGE_TIME) {
      setLocalItems(items);
    } else {
      setRequestItems(setValue, setValueFunction);
    }
  }, [ localStorageItem, checkHours, setLocalItems, setRequestItems ]);

  // use effect
  useDeepCompareEffect(() => {
    load(setValue, setValueFunction);
  }, [ setValue, setValueFunction ]);

  // render
  return (
    <div className="product-credits-fisic">
      <div className="wrapper">
        <div className="product-credits-fisic--content" dangerouslySetInnerHTML={{ __html: body }} />
      </div>

      <ProductCreditsFisicContent items={items} />
    </div>
  );
};

ProductCreditsFisic.propsTypes = {
  value: PropTypes.object.isRequired,
}

export default memo(ProductCreditsFisic);