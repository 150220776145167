import React from 'react';
import PropTypes from 'prop-types';

import ListContentList from './ListContentList';

import './list-content.scss';

// list content
const ListContentItem = ({ value: { listitems, subtype } }) => {
  // render
  return (
    <div className="list-content" data-type={subtype}>
      <div className="wrapper">
        <ListContentList items={listitems} type={subtype} />
      </div>
    </div>
  );
};

ListContentItem.propTypes = {
  value: PropTypes.any.isRequired,
};

export default ListContentItem;