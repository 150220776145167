import React from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../helpers/useLinkRefactory';

import ReadMoreInfo from './ReadMoreInfo';
import Picture from '../Picture';

import './read-more.scss';

// Read more
const ReadMore = ({ value }) => {
  // link element
  const { linkElement } = useLinkRefactory();

  // props
  const { description, image, subtype, title } = value;
  const link = linkElement(value);

  // render
  return (
    <div className="read-more" data-type={subtype}>
      <div className="wrapper">
        <ReadMoreInfo description={description} link={link} title={title} />
      
        <div className="read-more--image">
          <Picture image={image} />
        </div>
      </div>
    </div>
  );
};

ReadMore.propTypes = {
  value: PropTypes.object.isRequired,
}

export default ReadMore;