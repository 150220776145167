import React from 'react';
import PropTypes from 'prop-types';

import Picture from '../Picture';

import './profile.scss';

// profile
const Profile = ({ value }) => {
  // props
  const { title1, description1, title2, description2, title3, description3, title4, description4, image } = value;

  // render
  return (
    <div className="profile">
      <div className="wrapper">
        <div className="profile--item" data-type="1">
          <p className="title">{title1}</p>
          <p className="description">{description1}</p>
        </div>
        
        <div className="profile--item" data-type="2">
          <p className="title">{title2}</p>
          <p className="description">{description2}</p>
        </div>

        <div className="profile--image">
          <Picture image={image} />
        </div>

        <div className="profile--item" data-type="3">
          <p className="title">{title3}</p>
          <p className="description">{description3}</p>
        </div>

        <div className="profile--item" data-type="4">
          <p className="title">{title4}</p>
          <p className="description">{description4}</p>
        </div>

      </div>
    </div>
  );
};

Profile.propTypes = {
  value: PropTypes.object.isRequired
};

export default Profile;