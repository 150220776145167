import React from 'react';
import PropTypes from 'prop-types';

import ListStaticItem from '../ListStaticItem';

import './list-static-list.scss';

// list static list
const ListStaticList = ({ listitems }) => {
  // render
  return (
    <ul className="list-static--list">
      {listitems && listitems.map((item, index) => 
        <ListStaticItem item={item} key={index} index={index} />)}
    </ul>
  );
};

ListStaticList.propTypes = {
  listitems: PropTypes.array.isRequired,
};

export default ListStaticList;