import React from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../../../helpers/useLinkRefactory';

import LinkElement from '../../../LinkElement';
import Picture from '../../../Picture';

import './list-products-item.scss';

// List products item
const ListProductsItem = ({ item }) => {
  // props
  const { title1, title2, image, description } = item;

  // link element
  const { linkElement } = useLinkRefactory();
  const link = linkElement(item);

  // render
  return (
    <li className="list-products--item">
      <LinkElement className="link" link={link}>
        <div className="list-products--item--image">
          <Picture image={image} />
        </div>

        <div className="list-products--item--info">
          <p className="text">{title1}</p>
          <p className="text">{title2}</p>
          <p className="description">{description}</p>
        </div>
      </LinkElement>
    </li>
  );
};

ListProductsItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ListProductsItem;