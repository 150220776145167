import React from 'react';
import PropTypes from 'prop-types';

import ListCarrouselVideoGallery from './ListCarrouselVideoGallery';

import './list-carrousel-video.scss';

// list carrousel video
const ListCarrouselVideo = ({ value }) => {
  // props
  const { listitems, title } = value;

  // render
  return (
    <div className="list-carrousel-video">
      <div className="wrapper">
        <div className="list-carrousel-video--title">
          <h1 className="title">{title}</h1>
        </div>

        <ListCarrouselVideoGallery items={listitems} />
      </div>
    </div>
  );
};

ListCarrouselVideo.propTypes = {
  value: PropTypes.object.isRequired,
}

export default ListCarrouselVideo;