import React, { useCallback } from 'react';
import useImage from '../../helpers/useImage';

// picture
const Picture = ({ className, style, image, type }) => {
  // use image
  const { defaultImage, iconImage } = useImage();

  // get type
  const getType = useCallback((image, type) => {
    let img = null;

    switch (type) {
      case 2:
        img = iconImage(image);
        break;
      default:
        img = defaultImage(image);
        break;
    }

    return <img src={img.src} alt={img.alt} />
  }, [ defaultImage, iconImage ]);

  // render
  return (
    <picture className={className} style={style}>
      {getType(image, type)}
    </picture>
  );
};

export default Picture;