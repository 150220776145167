import React from 'react';
import PropTypes from 'prop-types';

// Button scroll
const ButtonScroll = ({ className, children, element }) => {
  // move down
  const moveDown = () => {
    if (element.current instanceof Object) {
      const { nextSibling: { offsetTop } } = element.current;

      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth'     
      });
    }
  };

  // render
  return (
    <button className={className} onClick={() => moveDown()}>{children}</button>
  );
};

ButtonScroll.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  element: PropTypes.object.isRequired,
};

export default ButtonScroll;