import React from 'react';
import PropTypes from 'prop-types';

import './box-alert.scss';

// box alert
const BoxAlert = ({ value }) => {
  // props
  const { description, title } = value;

  // render
  return (
    <div className="box-alert">
      <div className="wrapper">
        <div className="box-alert--content">
          <p className="title">{title}</p>
          <p className="description">{description}</p>
        </div>
      </div>
    </div>
  );
};

BoxAlert.propTypes = {
  value: PropTypes.object.isRequired,
};

export default BoxAlert;