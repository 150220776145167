import React from 'react';
import PropTypes from 'prop-types';

import './list-highlights-controls.scss';

// List Highlights Controls
const ListHighlightsControls = ({ current, total, getProperties, onPrevNext }) => {
  // render
  return (
    <div className="list-highlights--controls" data-view={total <= 4 ? false : true}>
      <button
        disabled={current <= 0}
        className="btn btn-control"
        onClick={() => onPrevNext('prev')}>
        <span className="icon icon-arrow-left"></span>
      </button>

      <button 
        disabled={current >= (total - getProperties().limit)}
        className="btn btn-control"
        onClick={() => onPrevNext('next')}>
        <span className="icon icon-arrow-right"></span>
      </button>
    </div>
  );
};

ListHighlightsControls.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  getProperties: PropTypes.func.isRequired,
  onPrevNext: PropTypes.func.isRequired,
};

export default ListHighlightsControls;