import React from 'react';
import PropTypes from 'prop-types';

import useLinkRefactory from '../../helpers/useLinkRefactory';

import LinkElement from '../LinkElement';
import TextSliderHighLightGallery from './TextSliderHighLightGallery';

import './text-slider-highlight.scss';

// text slider high light
const TextSliderHighLight = ({ value }) => {
  // link
  const { linkElement } = useLinkRefactory();

  // props
  const { abstract, title, listitems, cta_label } = value;
  const link = linkElement(value);

  // render
  return (
    <div className="text-slider-highlight">
      <div className="wrapper">
        <div className="text-slider-highlight--info">
          <h1 className="title">{title}</h1>
          <p className="description">{abstract}</p>

          {link &&
            <LinkElement className="btn btn-border w" link={link}>{cta_label}</LinkElement>}
        </div>
        
        <TextSliderHighLightGallery items={listitems} />
      </div>
    </div>
  );
};

TextSliderHighLight.propTypes = {
  value: PropTypes.object.isRequired,
};

export default TextSliderHighLight;