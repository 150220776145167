import React from 'react';
import PropTypes from 'prop-types';

import './webdoor-controls.scss';

// Webdoor controls
const WebdoorControls = ({ current, count, onPrevNext }) => {
  // render
  return (
    <div className="webdoor--controls">
      <button className="btn" data-type="prev" onClick={() => onPrevNext('prev')} disabled={current === 0}>
        <span className="icon icon-arrow-left"></span>
      </button>

      <button className="btn" data-type="next" onClick={() => onPrevNext('next')} disabled={current >= (count - 1)}>
        <span className="icon icon-arrow-right"></span>
      </button>
    </div>
  );
};

WebdoorControls.propTypes = {
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  onPrevNext: PropTypes.func.isRequired,
};

export default WebdoorControls;