import React, { useState } from 'react';

import axios from 'axios';
import { Formik, Form } from 'formik';

import useForm from '../../../helpers/useForm';

import Input from '../Input';
import FormsInfo from './FormsInfo';

// form credit juridic
const FormDenounce = ({ value: { title, body }}) => {
  // state
  const [ error, setError ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  // use form
  const { checkValue } = useForm();

  // initial
  const initialValues = {
    nome: '',
    email: '',
    ddd: '',
    telefone: '',
    mensagem: '',
    aceita_receber_comunicados: 'false'
  };
  // render
  return (
    <div className="form" data-type="denounce" data-loading={loading} data-error={error}>
      <div className="wrapper">
        <FormsInfo body={body} title={title} />

        <div className="form--inputs">
          <Formik
            //init
            initialValues={initialValues}
            initialStatus={{ success: false }}

            // validate
            validate={values => {
              const errors = {};
              const { nome, email, ddd, telefone, mensagem } = values;

              if (nome !== '' && !checkValue(nome, 'text'))
                errors.nome = true;

              if (email !== '' && !checkValue(email, 'email'))
                errors.email = true;

              if (ddd !== '' && !checkValue(ddd, 'number'))
                errors.ddd = true;

              if (telefone !== '' && !checkValue(telefone, 'phone'))
                errors.telefone = true;

              if (mensagem === '')
                errors.mensagem = true;

              setError(Object.keys(errors).length > 0);
              
              return errors;
            }}

            // on submit
            onSubmit={async (values, { setSubmitting, setStatus, resetForm }) => {
              setStatus({ success: false });
              resetForm(initialValues);
              setLoading(true);

              const params = values;
              params.telefone = params.ddd.toString() + params.telefone.toString();

              await axios.post('/api/v2/formularios/denuncia', {
                ...params
              }).then(e => setStatus({ success: true }))
                .then(e => setLoading(false))
                .catch(e => {});

              setSubmitting(false);
            }}>

            {({
              errors,
              status,
              isSubmitting,
            }) => (
              <Form autoComplete="off">
                <div className="input-group">
                  <Input label="Nome (opcional)" name="nome" type="text" error={errors.nome} theme="white" />
                </div>

                <div className="input-group">
                  <Input label="Email (opcional)" name="email" type="email" error={errors.email} theme="white" />
                </div>

                <div className="input-group join" data-error={errors.ddd || errors.telefone} data-theme="white">
                  <label className="label">telefone (opcional)</label>
                  <Input label="" name="ddd" type="ddd" error={errors.ddd} theme="white" />
                  <Input label="" name="telefone" type="phone" error={errors.telefone} theme="white" />
                </div>

                <div className="input-group">
                  <Input
                    label="sua mensagem"
                    name="mensagem"
                    type="textarea"
                    info="600 caracteres restantes"
                    error={errors.mensagem}
                    theme="white"
                    required={true} />
                </div>
                  
                {!status.success &&
                  <button
                    className="btn btn-default"
                    type="submit"
                    disabled={isSubmitting}>enviar</button>}
                
                {status.success === true &&
                  <div className="input-message">
                    <p className="message">
                      <span className="title">Obrigado por entrar em contato conosco.</span>
                      <span className="description">Sua mensagem será direcionada para a equipe responsável e logo entraremos em contato com você.</span>
                    </p>
                  </div>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FormDenounce;