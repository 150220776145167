import React, { createContext, useCallback, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import axios from 'axios';

import useNavigation from '../../helpers/useNavigation';
import useLocalStorage from '../../helpers/useLocalStorage';

// Navigation Context
const NavContext = createContext({
  items: [],
  routes: [],
  route: {},
  setRoute: () => {},
});

// Navigation Provider
const NavProvider = ({ children }) => {
  // use navigation
  const { getRoutes } = useNavigation();
  const { checkHours, localStorageItem, setValueFunction, setValue } = useLocalStorage('lecca_menu', { routes: [], date: Date.now() });

  // items
  const [ state, setState ] = useState({
    routes: [],
    route: {},
  });

  // set route
  const setRoute = useCallback((route, routes) => {
    if (route instanceof Object === false) return false;

    setState({
      routes,
      route: route,
    });
  }, [ setState ]);

  // set all routes
  const setAllRoutes = useCallback((routes, state) => {
    if (routes instanceof Object === false) return false;

    const allRoutes = getRoutes(routes);

    if (allRoutes instanceof Object) {
      setState({
        ...state,
        routes,
      });
    }
  }, [ getRoutes ]);

  // get menu
  const getMenu = useCallback(async (state, setValue, setValueFunction) => {
    const home = await axios.get(`/api/v2/pages/?slug=home`).then(({ data: { items }}) => items[0]);
        
    return axios.get(`/api/v2/pages/?type=paginas.pagina&fields=navegacao,retranca`)
      .then(({ data: { items }}) => {
        if (items instanceof Object) {
          if (home instanceof Object) items.unshift(home);
          items.map((item) => item.meta.html_url = decodeURIComponent(item.meta.html_url));
        }

        return items;
      })
      .then(items =>
        setValue('lecca_menu', { routes: items, date: Date.now() }, setValueFunction).then(() => setAllRoutes(items, state)));
  }, [ setAllRoutes ]);

  // load
  const load = useCallback((state, setValue, setValueFunction) => {
    const { routes, date } = localStorageItem;
    
    if (routes && routes.length > 0 && checkHours(date) < process.env.REACT_APP_LOCALSTORAGE_TIME) {
      setAllRoutes(routes, state);
    } else {
      getMenu(state, setValue, setValueFunction);
    }
  }, [ localStorageItem, setAllRoutes, checkHours, getMenu ]);

  // use effect
  useDeepCompareEffect(() => {
    load(state, setValue, setValueFunction);
  }, [ load, setValue, setValueFunction ]);

  // render
  return (
    <NavContext.Provider value={{
      ...state,
      setRoute,
    }}>
      {children}
    </NavContext.Provider>
  );
};

export { NavContext, NavProvider };
export default NavProvider;