import React from 'react';
import PropTypes from 'prop-types';

import { Parallax } from 'react-scroll-parallax';

import MissionVisionValuesItem from './MissionVisionValuesItem';

import './mission-vision-values.scss';

// mission vision values
const MissionVisionValues = ({ value }) => {
  // props
  const { body_mission, title_mission } = value;
  const { body_values, title_values } = value;
  const { body_vision, title_vision } = value;

  // render
  return (
    <div className="mission-vision-values">
      <div className="wrapper">
        <Parallax y={[-10, 0]}>
          <MissionVisionValuesItem body={body_mission} title={title_mission} type={1} />
        </Parallax>
        <MissionVisionValuesItem body={body_values} title={title_values} type={3} />
        <MissionVisionValuesItem body={body_vision} title={title_vision} type={2} />
      </div>
    </div>
  );
};

MissionVisionValues.propTypes = {
  value: PropTypes.object.isRequired,
};

export default MissionVisionValues;