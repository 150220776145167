import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// link
const LinkElement = ({ className, link, children, type }) => {
  // render
  return (
    <Fragment>
      {link.target !== '' && <a className={className}
          href={link.url}
          target={link.target}
          rel="noopener noreferrer"
          data-type={type}>
            {children}
        </a>}

      {!link.target && <Link className={className}
          to={link.url}
          data-type={type}>
          {children}
        </Link>}
    </Fragment>
  );
};

LinkElement.propTypes = {
  className: PropTypes.string,
  link: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

export default LinkElement;