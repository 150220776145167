import React from 'react';
import PropTypes from 'prop-types';

import './product-credits-fisic-content-gallery-controls.scss';

// product credits fisic content gallery controls
const ProductCreditsFisicContentGalleryControls = ({ count, current, onPrevNext, setItem }) => {
  // render
  return (
    <div className="product-credits-fisic-content-controls" data-active={count < 5}>
      <button className="btn btn-border" onClick={() => onPrevNext('prev')} disabled={current === 0}>
        <i className="icon icon-arrow-left"></i>
      </button>

      <button className="btn btn-border" onClick={() => onPrevNext('next')} disabled={current >= (count - 1)}>
        <i className="icon icon-arrow-right"></i>
      </button>

      <p className="label"><strong>{count}</strong>correspondentes</p>

      <button className="btn btn-border close" onClick={() => setItem({})}>
        <i className="icon icon-close"></i>
      </button>
    </div>
  );
};

ProductCreditsFisicContentGalleryControls.propTypes = {
  onPrevNext: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
  count: PropTypes.number,
  current: PropTypes.number.isRequired,
}

export default ProductCreditsFisicContentGalleryControls;