import React from 'react';
import PropTypes from 'prop-types';

import ListCarrouselVideoItem from '../ListCarrouselVideoItem';
import SliderBase from '../../../Slider/Base';
import SliderGallery from '../../../Slider/Gallery';

import './list-carrousel-video-gallery.scss';

// list carrousel video gallery
const ListCarrouselVideoGallery = ({ current, items, onPrevNext }) => {
  // render
  return (
    <div className="list-carrousel-video-gallery">
      <div className="list-carrousel-video-gallery--controls">
        <button
          className="btn btn-border"
          onClick={() =>onPrevNext('prev')} 
          disabled={current === 0}>
          <i className="icon icon-arrow-left"></i>
        </button>
  
        <button
          className="btn btn-border"
          onClick={() => onPrevNext('next')} 
          disabled={current >= (items.length - 1)}>
          <i className="icon icon-arrow-right"></i>
        </button>
      </div>

      {items && <SliderGallery
        className="list-carrousel-video-gallery"
        current={current}
        onPrevNext={onPrevNext}
        width={260}>
        {items.map((item, index) =>
          <ListCarrouselVideoItem {...item} key={index} />)}
      </SliderGallery>}
    </div>
  );
};

ListCarrouselVideoGallery.propTypes = {
  current: PropTypes.number.isRequired,
  items: PropTypes.array,
  onPrevNext: PropTypes.func.isRequired,
}

export default SliderBase(ListCarrouselVideoGallery);