import React from 'react';
import PropTypes from 'prop-types';

import './list-carrousel-photo-controls.scss';

// list carrousel photo controls
const ListCarrouselPhotoControls = ({ current, count, onPrevNext }) => {
  // render
  return (
    <div className="list-carrousel-photo-controls">
      <button className="btn btn-default d" onClick={() => onPrevNext('prev')} disabled={current === 0}>
        <span className="icon icon-arrow-left-l"></span>
      </button>
      <button className="btn btn-default d" onClick={() => onPrevNext('next')} disabled={current >= count}>
        <span className="icon icon-arrow-right-l"></span>
      </button>
    </div>
  );
};

ListCarrouselPhotoControls.propTypes = {
  current: PropTypes.number,
  count: PropTypes.number,
  onPrevNext: PropTypes.func.isRequired,
};

export default ListCarrouselPhotoControls;